import { ImageMenu } from "contents/func/type/typeinter";
import { atom } from "jotai";
import { atomFamily, atomWithStorage } from "jotai/utils";
import { MouseEvent, MouseEventHandler, ReactNode } from "react";

export const WriteAtom = atom({
    page : 1,
    title : '',
    create_at : new Date(),
    content : '',
    position : {
        name : null,
        lat : null,
        lng : null
    }
})
export const ImagePreviewAtom = atom([]);
export const ImageStateAtom = atom([]);
export const TagStateAtom = atom([]);
export const WindowStateAtom = atom({
    width : window.innerWidth,
    height : window.innerHeight,
    mobile : false 
})
export const ModalAtom = atomWithStorage('Modal-Atom-Key',false);

export const ProfileStateAtom = atom(null)
export const ContentStateAtom = atom({isLoading : false , isSuccess : true , data : {pages:[{data : [
        {id : 1 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트1' ,createAt : '2018/12/12 00:00:00',content : "fjdsklajfdlsa",
        hashtags : ['태그1','태그2','태그3','태그4'],
        imageLinks : ["https://apost.dev/content/images/2023/12/img-24.gif",
        "https://gongu.copyright.or.kr/gongu/wrt/cmmn/wrtFileImageView.do?wrtSn=9046601&filePath=L2Rpc2sxL25ld2RhdGEvMjAxNC8yMS9DTFM2L2FzYWRhbFBob3RvXzI0MTRfMjAxNDA0MTY=&thumbAt=Y&thumbSe=b_tbumb&wrtTy=10004"],commentCount : 13,likeCount : 1},
        {id : 2 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트2' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],
        imageLinks : [],commentCount : 1,likeCount : 13 , content : '<p>모궀로와 라잉으의 수니엘춤을 인논 오슨까로는 히스에 근열잘노로 돈탕에기 잉벼먼이어 히산다.</p> <p>캬앨더가 르쑤에 으으견, 언이렉쯤요 닷디조의 스간 오스게도. 아아겡온고 엔구 경컬무의 거우소앨건몰 전젒새내해를 노빤은. 낄라자학으러 즈룽람갼는다 려이츠더 도오홀몹으려고 죠식만주아앙 여보소는, 벨가춰있나노는, 며엡리혼 두구의, 누괴럭으라.</p> <p> 으됸 하으롤지고 틸둘로즈반다, 넹념누론이믜담 린첸안간게 심견노뽀다. 나널메사리에가 안믁 하낙시머에 마농뭐가 님주로 야어살게, 길액지 자자는 다기뱌다 산단과 도쿵이.</p> <p> 므토 히지홀 줄셩살이 운즈는 한흐는 를나와 니어면서 거부낙근보다, 욘히편과. 리각구뜔다 팟쟌스널다 랑낄이 우빈샆웃상 힘갈올놜 핸투아나의 이랴골다 사아는 낸죠아파남깅은 엇둣폰은.</p> <p> 흔누어 얼신준바지어 여은갈이 녹섹녀거냐 딧덋힙습니다.</p>'},
        {id : 3 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트3' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],
        imageLinks : [
            "https://velog.velcdn.com/images%2Faltmshfkgudtjr%2Fpost%2Fd93de2f8-e95b-48b9-bb0e-b64ef40b4fca%2F%EA%B7%B8%EB%A3%B9%2021.png",
            "https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif",
            "https://file.nodove.com/attach/images/43JfaPq3IzL16jJeGEF1-pGDnjgDQ259mhTubCSlQ4_L3lXaiI_2cCKIy9JAOqlLgYAQkQKtdwUkX7FI34gfrA.webp"
        ],commentCount : 1,likeCount : 13 , content : '<p>욪소의 처멸여슬디지 판아호아호의 뤼굼난 개몁셩으로 우갭다는 헤조억아가 아놔벤느나.</p> <p> 오옷도믜를 거졍이 미형긍기다 창고지요 두번호놔니 함애시다. 로이잉을 동가매 좌할길논은 브다사를 으드알멘사치.</p> <p> 합뗄딘메라답딤씨의 엄그군한세다면 랄알잴뎌제고 옷여도 겁길 다경너븨. 앋똭는 사나즐조는 게슨지 이윰와아가 그님린는 닝덱어 노다은란을 화줘어, 타조라니가. </p> <p>망자커징니히뎌다 그이사언는, 머츠깅앙암하다, 하아인간, 단도는 야버울아 댜너그다. 디위를 가겍운일 긔이드 화쩩은 친악징으의 사하는 저모알릉도.</p> <p> 자닙밪동고자 베숼타간치의 시헨 들지서곤완을, 라앰, 오닌빔어 윽추너닝데는 자하문앤 싸거긍싸루히는 녀저캐상투당, 읠긋자.</p> <p>'},
        {id : 4 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '자바스크립트로 만들수 있는것은 결국 자바스크립트로 만들어질것이다' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],
        imageLinks : ["https://modulabs.co.kr/wp-content/uploads/2023/11/image-1536x864.jpeg"],commentCount : 1,likeCount : 13},
        {id : 5 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '그저 JOAT액트' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],imageLinks : ["https://images.velog.io/images/fromzoo/post/25badbb6-d09e-4061-88d2-0f91b84d528b/reactLogo.jpeg"],commentCount : 1,likeCount : 13},
        {id : 6 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트6' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],imageLinks : ["https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif","https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif","https://file.nodove.com/attach/images/43JfaPq3IzL16jJeGEF1-pGDnjgDQ259mhTubCSlQ4_L3lXaiI_2cCKIy9JAOqlLgYAQkQKtdwUkX7FI34gfrA.webp"],commentCount : 1,likeCount : 13},
        {id : 7 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트7' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],imageLinks : ["https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif","https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif","https://file.nodove.com/attach/images/43JfaPq3IzL16jJeGEF1-pGDnjgDQ259mhTubCSlQ4_L3lXaiI_2cCKIy9JAOqlLgYAQkQKtdwUkX7FI34gfrA.webp"],commentCount : 1,likeCount : 13},
        {id : 8 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트1' ,createAt : '2018/12/12 00:00:00',content : "fjdsklajfdlsa",
        hashtags : ['태그1','태그2','태그3','태그4'],
        imageLinks : ["https://apost.dev/content/images/2023/12/img-24.gif",
        "https://gongu.copyright.or.kr/gongu/wrt/cmmn/wrtFileImageView.do?wrtSn=9046601&filePath=L2Rpc2sxL25ld2RhdGEvMjAxNC8yMS9DTFM2L2FzYWRhbFBob3RvXzI0MTRfMjAxNDA0MTY=&thumbAt=Y&thumbSe=b_tbumb&wrtTy=10004"],commentCount : 13,likeCount : 1},
        {id : 9 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트2' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],
        imageLinks : [],commentCount : 1,likeCount : 13 , content : '<p>모궀로와 라잉으의 수니엘춤을 인논 오슨까로는 히스에 근열잘노로 돈탕에기 잉벼먼이어 히산다.</p> <p>캬앨더가 르쑤에 으으견, 언이렉쯤요 닷디조의 스간 오스게도. 아아겡온고 엔구 경컬무의 거우소앨건몰 전젒새내해를 노빤은. 낄라자학으러 즈룽람갼는다 려이츠더 도오홀몹으려고 죠식만주아앙 여보소는, 벨가춰있나노는, 며엡리혼 두구의, 누괴럭으라.</p> <p> 으됸 하으롤지고 틸둘로즈반다, 넹념누론이믜담 린첸안간게 심견노뽀다. 나널메사리에가 안믁 하낙시머에 마농뭐가 님주로 야어살게, 길액지 자자는 다기뱌다 산단과 도쿵이.</p> <p> 므토 히지홀 줄셩살이 운즈는 한흐는 를나와 니어면서 거부낙근보다, 욘히편과. 리각구뜔다 팟쟌스널다 랑낄이 우빈샆웃상 힘갈올놜 핸투아나의 이랴골다 사아는 낸죠아파남깅은 엇둣폰은.</p> <p> 흔누어 얼신준바지어 여은갈이 녹섹녀거냐 딧덋힙습니다.</p>'},
        {id : 10 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트3' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],
        imageLinks : [
            "https://velog.velcdn.com/images%2Faltmshfkgudtjr%2Fpost%2Fd93de2f8-e95b-48b9-bb0e-b64ef40b4fca%2F%EA%B7%B8%EB%A3%B9%2021.png",
            "https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif",
            "https://file.nodove.com/attach/images/43JfaPq3IzL16jJeGEF1-pGDnjgDQ259mhTubCSlQ4_L3lXaiI_2cCKIy9JAOqlLgYAQkQKtdwUkX7FI34gfrA.webp"
        ],commentCount : 1,likeCount : 13 , content : '<p>욪소의 처멸여슬디지 판아호아호의 뤼굼난 개몁셩으로 우갭다는 헤조억아가 아놔벤느나.</p> <p> 오옷도믜를 거졍이 미형긍기다 창고지요 두번호놔니 함애시다. 로이잉을 동가매 좌할길논은 브다사를 으드알멘사치.</p> <p> 합뗄딘메라답딤씨의 엄그군한세다면 랄알잴뎌제고 옷여도 겁길 다경너븨. 앋똭는 사나즐조는 게슨지 이윰와아가 그님린는 닝덱어 노다은란을 화줘어, 타조라니가. </p> <p>망자커징니히뎌다 그이사언는, 머츠깅앙암하다, 하아인간, 단도는 야버울아 댜너그다. 디위를 가겍운일 긔이드 화쩩은 친악징으의 사하는 저모알릉도.</p> <p> 자닙밪동고자 베숼타간치의 시헨 들지서곤완을, 라앰, 오닌빔어 윽추너닝데는 자하문앤 싸거긍싸루히는 녀저캐상투당, 읠긋자.</p> <p>'},
        {id : 11 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '자바스크립트로 만들수 있는것은 결국 자바스크립트로 만들어질것이다' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],
        imageLinks : ["https://modulabs.co.kr/wp-content/uploads/2023/11/image-1536x864.jpeg"],commentCount : 1,likeCount : 13},
        {id : 12 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '그저 JOAT액트' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],imageLinks : ["https://images.velog.io/images/fromzoo/post/25badbb6-d09e-4061-88d2-0f91b84d528b/reactLogo.jpeg"],commentCount : 1,likeCount : 13},
        {id : 13 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트6' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],imageLinks : ["https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif","https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif","https://file.nodove.com/attach/images/43JfaPq3IzL16jJeGEF1-pGDnjgDQ259mhTubCSlQ4_L3lXaiI_2cCKIy9JAOqlLgYAQkQKtdwUkX7FI34gfrA.webp"],commentCount : 1,likeCount : 13},
        {id : 14 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트7' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],imageLinks : ["https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif","https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif","https://file.nodove.com/attach/images/43JfaPq3IzL16jJeGEF1-pGDnjgDQ259mhTubCSlQ4_L3lXaiI_2cCKIy9JAOqlLgYAQkQKtdwUkX7FI34gfrA.webp"],commentCount : 1,likeCount : 13},
        {id : 15 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트1' ,createAt : '2018/12/12 00:00:00',content : "fjdsklajfdlsa",
        hashtags : ['태그1','태그2','태그3','태그4'],
        imageLinks : ["https://apost.dev/content/images/2023/12/img-24.gif",
        "https://gongu.copyright.or.kr/gongu/wrt/cmmn/wrtFileImageView.do?wrtSn=9046601&filePath=L2Rpc2sxL25ld2RhdGEvMjAxNC8yMS9DTFM2L2FzYWRhbFBob3RvXzI0MTRfMjAxNDA0MTY=&thumbAt=Y&thumbSe=b_tbumb&wrtTy=10004"],commentCount : 13,likeCount : 1},
        {id : 16 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트2' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],
        imageLinks : [],commentCount : 1,likeCount : 13 , content : '<p>모궀로와 라잉으의 수니엘춤을 인논 오슨까로는 히스에 근열잘노로 돈탕에기 잉벼먼이어 히산다.</p> <p>캬앨더가 르쑤에 으으견, 언이렉쯤요 닷디조의 스간 오스게도. 아아겡온고 엔구 경컬무의 거우소앨건몰 전젒새내해를 노빤은. 낄라자학으러 즈룽람갼는다 려이츠더 도오홀몹으려고 죠식만주아앙 여보소는, 벨가춰있나노는, 며엡리혼 두구의, 누괴럭으라.</p> <p> 으됸 하으롤지고 틸둘로즈반다, 넹념누론이믜담 린첸안간게 심견노뽀다. 나널메사리에가 안믁 하낙시머에 마농뭐가 님주로 야어살게, 길액지 자자는 다기뱌다 산단과 도쿵이.</p> <p> 므토 히지홀 줄셩살이 운즈는 한흐는 를나와 니어면서 거부낙근보다, 욘히편과. 리각구뜔다 팟쟌스널다 랑낄이 우빈샆웃상 힘갈올놜 핸투아나의 이랴골다 사아는 낸죠아파남깅은 엇둣폰은.</p> <p> 흔누어 얼신준바지어 여은갈이 녹섹녀거냐 딧덋힙습니다.</p>'},
        {id : 17 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트3' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],
        imageLinks : [
            "https://velog.velcdn.com/images%2Faltmshfkgudtjr%2Fpost%2Fd93de2f8-e95b-48b9-bb0e-b64ef40b4fca%2F%EA%B7%B8%EB%A3%B9%2021.png",
            "https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif",
            "https://file.nodove.com/attach/images/43JfaPq3IzL16jJeGEF1-pGDnjgDQ259mhTubCSlQ4_L3lXaiI_2cCKIy9JAOqlLgYAQkQKtdwUkX7FI34gfrA.webp"
        ],commentCount : 1,likeCount : 13 , content : '<p>욪소의 처멸여슬디지 판아호아호의 뤼굼난 개몁셩으로 우갭다는 헤조억아가 아놔벤느나.</p> <p> 오옷도믜를 거졍이 미형긍기다 창고지요 두번호놔니 함애시다. 로이잉을 동가매 좌할길논은 브다사를 으드알멘사치.</p> <p> 합뗄딘메라답딤씨의 엄그군한세다면 랄알잴뎌제고 옷여도 겁길 다경너븨. 앋똭는 사나즐조는 게슨지 이윰와아가 그님린는 닝덱어 노다은란을 화줘어, 타조라니가. </p> <p>망자커징니히뎌다 그이사언는, 머츠깅앙암하다, 하아인간, 단도는 야버울아 댜너그다. 디위를 가겍운일 긔이드 화쩩은 친악징으의 사하는 저모알릉도.</p> <p> 자닙밪동고자 베숼타간치의 시헨 들지서곤완을, 라앰, 오닌빔어 윽추너닝데는 자하문앤 싸거긍싸루히는 녀저캐상투당, 읠긋자.</p> <p>'},
        {id : 18 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '자바스크립트로 만들수 있는것은 결국 자바스크립트로 만들어질것이다' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],
        imageLinks : ["https://modulabs.co.kr/wp-content/uploads/2023/11/image-1536x864.jpeg"],commentCount : 1,likeCount : 13},
        {id : 19 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '그저 JOAT액트' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],imageLinks : ["https://images.velog.io/images/fromzoo/post/25badbb6-d09e-4061-88d2-0f91b84d528b/reactLogo.jpeg"],commentCount : 1,likeCount : 13},
        {id : 20 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트6' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],imageLinks : ["https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif","https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif","https://file.nodove.com/attach/images/43JfaPq3IzL16jJeGEF1-pGDnjgDQ259mhTubCSlQ4_L3lXaiI_2cCKIy9JAOqlLgYAQkQKtdwUkX7FI34gfrA.webp"],commentCount : 1,likeCount : 13},
        {id : 21 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , title : '게시글 테스트7' ,createAt : '2018/12/12 00:00:00',hashtags : ['태그1','태그2','태그3','태그4'],imageLinks : ["https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif","https://file.nodove.com/attach/images/cat-cat-gun.gif560acab5-2fdc-444c-8a93-b43e211447d8.gif","https://file.nodove.com/attach/images/43JfaPq3IzL16jJeGEF1-pGDnjgDQ259mhTubCSlQ4_L3lXaiI_2cCKIy9JAOqlLgYAQkQKtdwUkX7FI34gfrA.webp"],commentCount : 1,likeCount : 13},
    ]}]}}
)
interface CurrentUserAtom{
    "userId" : String,
    "nickname" : String,
    "role" : {"authority" : String},
    iat : Number,
    exp : Number,
    type : String,
}
export const CurrentUserAtom = atom<CurrentUserAtom|null>(null);
export const CurrentStateAtom = atom<ReactNode|null>(null);
export const MyTagStateAtom = atom([])

export const pageSettingAtom = atom({
        "main" : "/",
        "login" : "/user/login",
        "join" : "/user/join",
        "search" : "/search",
        "list" : "/list",
        "alert" : "/alert",
        "view" : "/view",
        "imgPreview" : "img",
        "userInfo" : "/user/info",
        "userEdit" : "/user/edit",
        "collected" : "/collected",
        "findUser" : "/find/user",
        "map":"/map",
        "messenger":process.env.REACT_APP_CHAT_SERVER_DOMAIN,
        "write":"/write",
        "setting" : "/setting",
        "help" : "/help",
        "bookmark" : '/bookmark',
        "detail" : 'detail',
        "tag" : '/search/tag',
        "myTag" : '/myInfo/tag',
        "category" : 'category',
        "etc" : "/etc",
        "naverAuth" : '/cb/n',
        "kakaoAuth" : '/cb/k',
        "googleAuth" : '/cb/g',
})

export const CatePageAtom = atomWithStorage('category-page-key', null)
export const RefreshQueryStateAtom = atom<Array<any>|null>(null);
export const HasAlertRefreshedAtom = atomWithStorage('has-alert-refreshed-key',false);
export const UserInfoPageAtom = atom(0);

/* menu state */
export const BigMenuObjectAtom = atom<ReactNode|null>(null);
export const MenuObjectAtom = atom<ReactNode|null>(null);
export const MenuTargetAtom = atom<MouseEvent|null>(null);
export const PostModalObjectAtom = atom<ReactNode|null>(null);
export const NavbarLeftObjectAtom = atom<ReactNode|null>(null);
export const NavbarLeftTargetAtom = atom<MouseEvent|null>(null);
export const AlertObjectAtom = atom<ReactNode|null>(null);
export const AlertTargetAtom = atom<MouseEvent|null>(null);
export const CateRefAtom = atom<any>(null);
export const TagRefAtom = atom<any>(null);
export const ListRowScrollAtom = atom(null);
export const ImageEditorLinkAtom = atom<string|null>(null);
export const ImageEditorOnAtom = atom<boolean>(false);
export const ImageUIAtom = atom<ReactNode|null>(null);
export const SearchFormOnAtom = atom<boolean>(false);

export const ImageMenuAtom = atom<ImageMenu|null>(null);
export const ImageTargetAtom = atom(null);

export const AlertListAtom = atom<String[]>([]);

export const CollectedBooleanAtom = atomWithStorage('collected-view-key',false);