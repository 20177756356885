import { CustomBtn } from "contents/func/anim/custom";
import { BigMenu, MobileView } from "contents/interface/mobile";
import { BigMenuObjectAtom, MenuObjectAtom, MenuTargetAtom} from "contents/state/state";
import { ShareBtn } from "icons";
import React, { createContext, useContext, useState } from "react";
import { Link } from "react-router-dom";
import shareStyle from "./share/share.module.css";
import { SmallSlick } from "contents/func/img/slick/slickOpt";
import {Kakao,Oauth,Naver,Telegram,Facebook,Twitter} from "icons"
import { Alert, useSetAlert } from "contents/func/toast/toast";
import { useSetAtom } from "jotai";
import cn from "classnames/bind";
import useShare from "contents/func/hooks/share";

const cx = cn.bind(shareStyle);

const shareContext = createContext(null);

export const ShareComp = ({title,text,url = ''}) =>{
    const targetUrl = url;
    const reg = /<[^>]*>?/g
    const setObject = useSetAtom(BigMenuObjectAtom);
    const setTarget = useSetAtom(MenuTargetAtom);

    async function Sharing(e){
        setTarget(e);
        setObject(<MobileView><ShareAPI title = {title} text = {text.replace(reg,"")} targetUrl={targetUrl}/></MobileView>);
    }

    return (
        <CustomBtn className="list-row-share-btn flex-row btn-style-grey-back"
        onClick={Sharing}>
            <ShareBtn width="24px" height='100%' fill="inherit" stroke="inherit" className="list-row-share-svg" style={{pointerEvents : 'none'}}/>
        </CustomBtn>
    );
}



export const ShareAPI = ({title,text,targetUrl}) =>{
    const {copyUrl} = useShare();

    return (
        <div className={cx('menuSub',"flex-column")}>
            <CustomBtn className={cx("menuSubMenu","flex-row")} onClick={()=>copyUrl(targetUrl)}>링크 복사하기</CustomBtn>
            <div className={cx("menuSubShare",'flex-column')}>
                <p>다음으로 공유 : </p>
                <shareContext.Provider value={{title,text,targetUrl}}>
                    <SmallSlick Comps={[<ShareKakao/>,<ShareNaver/>,<ShareFacebook/>,<ShareTwitter/>,<ShareTelegram/>]}/>
                </shareContext.Provider>
            </div>
        </div>
    );
}

// KAKAO
function ShareKakao() {
    const {title,targetUrl} = useContext(shareContext);
    const share = () => {}

    return(
        <img src={Kakao} width = '64' height = '64' onClick={share}/>
    );
}

// NAVER
function ShareNaver() {
    const {title,targetUrl} = useContext(shareContext);
    const share = () => window.open(
    "https://share.naver.com/web/shareView?url=" + targetUrl + "&title=" + title
    );

    return(
        <img src={Naver} width = '64' height = '64' onClick={share}/>
    );
}

// Facebook
function ShareFacebook() {
    const {title,targetUrl} = useContext(shareContext);
    const share = () => window.open(
    "http://www.facebook.com/sharer/sharer.php?u=" + targetUrl
    );

    return(
        <img src={Facebook} width = '64' height = '64' onClick={share}/>
    );
}

// Twitter
function ShareTwitter() {
    const {text,targetUrl} = useContext(shareContext);
    const share = () => window.open(
    "https://twitter.com/intent/tweet?text=" + targetUrl + text
    );
    return(
        <img src={Twitter} width = '64' height = '64' onClick={share}/>
    );
}

// Telegram
function ShareTelegram() {
    const {targetUrl} = useContext(shareContext);
    const share = () => window.open("https://telegram.me/share/url?url=" + targetUrl);

    return(
        <img src={Telegram} width = '64' height = '64' onClick={share}/>
    );
}