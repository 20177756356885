import { CustomBtn, CustomLink } from "contents/func/anim/custom";
import navbarStyle from "./css/navbar.module.css";
import cn from "classnames/bind";
import { AlertBtn, AlertBtnEmpty, LeftBtn, MenuBtn, SearchBtn, SettingBtn } from "icons";
import { HasAlertRefreshedAtom, MenuObjectAtom, MenuTargetAtom, ModalAtom, pageSettingAtom, SearchFormOnAtom } from "contents/state/state";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { To, useLocation, useNavigate } from "react-router-dom";
import { UserEtc } from "contents/user/info/userInfo";
import { ReactNode } from "react";

const cx = cn.bind(navbarStyle);
const defaultSize = 18;

export const NavbarTitle = ({title} : {title : string}) =>{
    return (
        <div className={cx('navbar-title','only-mobile')}>
            {title}
        </div>
    );
}

export const SearchNavBtn = () => {
    const setSearchOn = useSetAtom(SearchFormOnAtom);
    return(
        <CustomBtn className = {cx("navbar-top-items","only-mobile-flex","flex-column")} onClick={()=>setSearchOn(true)}>
            <SearchBtn width={defaultSize} height={defaultSize} fill='inherit'/>
        </CustomBtn>
    );
}

export const EtcNavBtn = ({object} : {object : ReactNode}) =>{
    const setObject = useSetAtom(MenuObjectAtom);
    const setTarget = useSetAtom(MenuTargetAtom);

    return (
        <CustomBtn onClick={(e)=>{setObject(object); setTarget(e);}} className = {cx("navbar-top-items","only-mobile-flex","flex-column")}>
            <p style={{fontWeight:'bold' , fontSize : '100%' , writingMode : "vertical-lr"}}>•••</p>
        </CustomBtn>
    );
}

export const SettingNavBtn = () => {
    const pageSetting = useAtomValue(pageSettingAtom);
    return(
        <CustomLink to={pageSetting.setting} className = {cx("navbar-top-items","only-mobile-flex","flex-column")}>
            <SettingBtn width={defaultSize} height={defaultSize} fill='inherit'/>
        </CustomLink>
    );
}

export const BackNavBtn = ({backRoute} : {backRoute? : To}) =>{
    const navigate = useNavigate();
    return (
        <CustomBtn className = {cx("navbar-left-menu-button","flex-row")}
        onClick={()=>{
            if (backRoute)
                navigate(backRoute);
            else
                navigate(-1);
        }}>
            <LeftBtn width={defaultSize} height={defaultSize} fill="inherit"/>
        </CustomBtn>
    );
}

export const DrawerNavBtn = () =>{
    const [menuOn,setMenuOn] = useAtom(ModalAtom);
    return (
        <CustomBtn className = {cx("navbar-top-items","only-mobile-flex","flex-column")} onClick={()=>setMenuOn(true)}>
            <MenuBtn width={defaultSize} height={defaultSize} fill='inherit'/>
        </CustomBtn>
    );
}