import React, { useContext } from "react";
import { listContext } from ".";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DateUnit, getUnit} from "contents/func/unit";
import { HeartBtn, MsgBtn} from "icons/index";
import { WindowStateAtom, pageSettingAtom } from "contents/state/state";
import styles from "./css/colRow.module.css";
import MainStyle from "contents/main/main.module.css";
import { CustomBtn } from "contents/func/anim/custom";
import { useAtom, useAtomValue } from "jotai";
import cn from "classnames/bind";
import MinimalRow from "./minimal";
import { FeedMenu } from "../view/manipulate";

const cx = cn.bind(styles);
const cs = cn.bind(MainStyle);

export const ColRow = ({row = 1} : {row : number}) => {
    const {arr} = useContext(listContext);
    const navigate = useNavigate();
    const pageSetting = useAtomValue(pageSettingAtom);

    return (
        <CustomBtn style={{backgroundImage : `url('${arr.imageLinks?.filter((e : any)=>e)[0]}')`, width : `${(100/row)}%`}}
        onClick={()=>{
            navigate(`${pageSetting.list}?page=${arr.id}`);
        }} className = {styles.collectedContainer + " flex-column"}>
            <MinimalRow className={cx('infoContainer')}
            arr={arr} cStyle={{title : {fontSize : '18px'},writer:{fontSize : '14px'}}}
            menu = {<FeedMenu id={arr.writerUserId} postId={arr.id}/>}
            />
        </CustomBtn>
    );
}

export const SkelColRow = () =>{
    return (
        <div className = {styles.collectedContainer + " skel-draw flex-column"} style={{aspectRatio : 1/1}}>
            <div className = {styles.images}/>
        </div>
    );
}