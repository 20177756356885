import base64 from "base-64"
import { AxiosIns, retry } from "contents/func/network/axios";

/* 토큰 디코딩(default값 => localStorage에서 userToken값 가져옴) */
export const decodingToken = async(jwt) => {
    return await new Promise(async (resolve,rej)=>{
    if (!jwt) return rej('jwt 토큰이 없습니다');
    return await parsingToken(jwt)
    .then(async(parsed)=>{
        if (localStorage.getItem('provider')){
            await AxiosIns.get('/api/restrict/user/userInfo')
            .then((res)=>{
                resolve({cachedInfo : jwt, parsed : res.data});
            }).catch((err)=>{
                rej(err);
            })
        }
        else {
            const currentTimeStamp = ~~(Date.now() / 1000);
            console.log(currentTimeStamp,parsed.exp)
            if (parsed.exp > currentTimeStamp){
                cacheRefresh(jwt);
                resolve({cachedInfo : jwt, parsed : parsed});
            }
            else rej();
        }
    })
    .catch((err)=>{
        rej(err)
    })
});
}

export const cacheRefresh = (jwt) =>{
    return new Promise((resolve,rej)=>{
        if (!jwt) return rej('JWT 토큰을 찾을수 없습니다.');
        try {
            localStorage.removeItem('userToken');
            localStorage.removeItem('provider');
            localStorage.setItem('userToken', jwt);
            localStorage.setItem('provider',"server")
            resolve(jwt);
        } catch (err){
            rej(err);
        }
    });
}

export const parsingToken = (jwt) =>{
    return new Promise((res,rej)=>{
        if (!jwt) return rej();
        let payload = jwt.substring(jwt.indexOf('.') + 1, jwt.lastIndexOf('.'));
        let decodeToken = base64.decode(payload).toString('utf8');
        if (decodeToken) res(JSON.parse(decodeToken));
        else rej();
    })
}