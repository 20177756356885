import React, { useEffect, useRef, useState} from "react";
import ReactDom from "react-dom";
import { MenuBackWrapper } from "./wrapper";
import { BigMenuObjectAtom, BigMenuTargetAtom, ImageEditorLinkAtom, ImageEditorOnAtom, ImageMenuAtom, ImageTargetAtom, ImageUIAtom, MenuObjectAtom, MenuTargetAtom, WindowStateAtom} from "contents/state/state";
import { TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useSwipe } from "contents/func/hooks/interface";
import interStyle from "./css/interface.module.css";
import cn from "classnames/bind"
import { CustomClose } from "contents/func/anim/custom";
import { ImgEditor } from "contents/func/img/editor/editor";
import { VideoControl } from "contents/video/custom";
import { SlideMotion } from "contents/func/anim/motions";

const cx = cn.bind(interStyle);

export const BigMenu = () => {
    const [windowState,] = useAtom(WindowStateAtom);
    const [smallObj,setSmallObj] = useAtom(MenuObjectAtom);
    const [Object,setObject] = useAtom(BigMenuObjectAtom);
    const [target,setTarget] = useAtom(MenuTargetAtom);

    const reset = () =>{
        setObject(null);
        setSmallObj(null);
        setTarget(null);
    }

    useEffect(()=>{
        return ()=>{
            reset();
        }
    },[windowState.mobile]);

    return ReactDom.createPortal(
            <MenuBackWrapper isOpened = {target&&(!smallObj)} cb = {()=>reset()} blackBack={true} className = {cx("only-mobile")}>
                <div className={cx("bigmenu-container")}
                onClick={(e)=>e.stopPropagation()}
                style={{opacity : (target)?'1':'0' , visibility : (Object)?'visible':'hidden' , bottom : (target)?'0':'-100dvh'}}>
                    {Object}
                </div>
            </MenuBackWrapper>
    ,document.querySelector('#portal')
    );
}

export const ImgPreview = () => {
    const bigMenuRef = useRef();
    const windowState = useAtomValue(WindowStateAtom)
    const [active,setActive,hactive,setHActive] = useSwipe(bigMenuRef,windowState.height * 2/3,windowState.width * 0.5);
    const [object,setObject] = useAtom(ImageMenuAtom);
    const [controller,setController] = useAtom(ImageUIAtom);
    const [on,setOn] = useAtom(ImageTargetAtom);
    const [y,setY] = useState(0);
    const [scale,setScale] = useState(0);

    useEffect(()=>{
        if (object) setY(0);
        return ()=>setY(0);
    },[object]);

    useEffect(()=>{
        if (scale < 1.05){
            if (hactive == -1) {
                setObject((p)=>{return {...p , index : Math.min(object.image.length , p['index'] + 1)}});
            }
            else if (hactive == 1) {
                setObject((p)=>{return {...p , index : Math.max(0 , p['index'] - 1)}});
            }
            if (active){
                setY(active * windowState.height);
                setOn(false);
            }
        }
        if (setHActive != 0) setHActive(0);
        if (setActive != 0) setActive(0);
        return()=>{
            setHActive(0);
            setActive(0);
        }
    },[active,hactive,scale]);

    return ReactDom.createPortal(
        <MenuBackWrapper isOpened = {on} blackBack={true}>
            <div className={cx("img-preview-container")} ref = {bigMenuRef}
            style={{visibility : (on)?'visible':'hidden' , opacity : (on)?'1':'0'}}>
                <CustomClose onClick={()=>setOn(0)}/>
                <div className={cx("img-preview-slick-container")} style={{transform : `translateY(${y}px)`}}>       
                    <div className={cx("img-preview-slick","flex-row","scrolling")}>
                        <TransformWrapper onZoom={(e)=>setScale(e.state?.scale)} initialScale={1} minScale={1} maxScale={10} initialPositionX={0} initialPositionY={0}>
                            <TransformComponent>
                                <SlideMotion PageKey={object?.index} direction={-1 * hactive}>
                                    {object?.image[object?.index]}
                                </SlideMotion>
                            </TransformComponent>
                        </TransformWrapper>
                    </div>
                    {controller}
                </div>
            </div>
        </MenuBackWrapper>
        ,document.querySelector('#portal_top')
    );
}

export const MobileView = ({title = "" , children}) =>{
    return(
        <div className={cx("mobile-half-view-container",'flex-column')}>
            {(title)&&
            <div className={cx("mobile-half-view-header","flex-column")}>
                {title}
            </div>}
            {children}
        </div>
    );
}

export const ImgEditView = ({cb = (str)=>{}, index}) => {
    const bigMenuRef = useRef();
    const [on,setOn] = useAtom(ImageEditorOnAtom);
    console.log(on);

    return ReactDom.createPortal(
        <MenuBackWrapper isOpened = {on} blackBack={true}>
            <div className={cx("img-preview-container")} ref = {bigMenuRef}
            style={{visibility : (on)?'visible':'hidden' , opacity : (on)?'1':'0'}}>
                <ImgEditor cb={(link)=>{setOn(false); if(cb) cb(link,index);}}/>
            </div>
        </MenuBackWrapper>
        ,document.querySelector('#portal')
    );
}