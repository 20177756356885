import React, { createContext, Fragment, useEffect, useRef, useState } from "react";
import { useNavigate} from "react-router-dom";
import { CateRefAtom, MenuObjectAtom, MenuTargetAtom, NavbarLeftObjectAtom, NavbarLeftTargetAtom, RefreshQueryStateAtom, WindowStateAtom, pageSettingAtom } from "../state/state";
import { MapContainer } from "../map/map";
import styles from "./main.module.css";
import banner1 from "./banner/banner1.png";
import banner2 from "./banner/banner2.png";
import banner3 from "./banner/banner3.png";
import banner4 from "./banner/banner4.png";
import { useQueries, useQuery, useQueryClient } from "react-query";
import { AxiosIns } from "contents/func/network/axios";
import DynamicMeta from "dynamicMeta";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { CustomBtn, CustomImg, CustomLink } from "contents/func/anim/custom";
import cn from "classnames/bind";
import tagStyles from "contents/func/tag/tags.module.css";
import { RightBtn, ShareBtn} from "icons";
import CatePage from "contents/post/cate/cate";
import { MyTagPage } from "contents/func/tag/tagpage";
import MinimalRow, { MinimalRowSkel } from "contents/post/list/minimal";
import { ShareAPI, } from "contents/share/share";
import { PropsData } from "contents/func/type/typeinter";
import { useSetAlert } from "contents/func/toast/toast";
import { LeftArrow, RightArrow } from "contents/func/img/slick/slickOpt";

const cx = cn.bind(styles);
const cs = cn.bind(tagStyles);

const MainPage = () =>{
    const [pageSetting,] = useAtom(pageSettingAtom);
    const navigate = useNavigate();
    const contentMax = 7;

    const [,QueryToRefresh] = useAtom(RefreshQueryStateAtom);
    const first = 2;
    const second = 3;
    const setLeftObject = useSetAtom(NavbarLeftObjectAtom);
    const setTarget = useSetAtom(NavbarLeftTargetAtom);
    const defaultCate = useAtomValue(CateRefAtom);
    const windowState = useAtomValue(WindowStateAtom);
    const firstList = useQuery(['Get-Main-Content-List-Key','Start-Up-Key','first'],()=>AxiosIns.get(`/api/getPostByList/0?pageSize=${contentMax}&categoryId=${first}`).then(res=>res.data));
    const secondList = useQuery(['Get-Main-Content-List-Key','Start-Up-Key','second'],()=>AxiosIns.get(`/api/getPostByList/0?pageSize=${contentMax}&categoryId=${second}`).then(res=>res.data));
    
    useEffect(()=>{
        QueryToRefresh(['Get-Main-Content-List-Key'])
    },[])

    return (
        <div>
            <DynamicMeta title={'메인페이지'} description={'나만의 작은 편리함'} size={{width: "540px",height: "540px"}} url={''} />
            <PartComp className = {styles.follower}>
                <div className={styles.partContent}>
                    <ContentComp title={"커리어블록"} list = {firstList} category={first}/>
                </div>
            </PartComp>
            <div className={styles.page + ""}>
                <PartComp title = "나의 구독" onClick={(e : any)=>{if(windowState.mobile){navigate(pageSetting.category)} setLeftObject(<CatePage/>); setTarget(e);}} className = {styles.heat + " row-container"}>

                </PartComp>
            </div>
            <PartComp className = {styles.follower}>
                <div className={styles.partContent}>
                    <ContentComp title={"창업정보"} list = {secondList} category={second}/>
                </div>
            </PartComp>
            <div className={styles.page + ""}>
                <PartComp title = "#최신 #해시태그"  onClick={(e : any)=>{if(windowState.mobile){navigate(pageSetting.myTag)} setLeftObject(<MyTagPage/>); setTarget(e);}} className = {styles.tags + " row-container"}>
                    <MainHotTag/>
                </PartComp>
                <PartComp title = "내 근처" onClick = {()=>navigate(pageSetting.map)} className={styles.map + " row-container"}>
                    <MapContainer draggable={false} searchData={{}} cb={()=>{navigate(pageSetting.map)}}/>
                </PartComp>
            </div>

            <div className={styles.page + ""}>
            </div>
        </div>
    );
}

const PartComp = ({title,onClick,children,className = styles.etc}
    : {size? : {row : string , column : string},title?:string , onClick? : any , children? : any,className? : string}) =>{
    return (
        <div className={styles.pageContainer + " flex-column " + className}>
            {(title)&&
            <div onClick={onClick} className={styles.top + " flex-row"} style={{cursor : (onClick)?'pointer':'auto'}}>
                {title}
                <RightBtn width = '24' height = '12px' stroke = "inherit"/>
            </div>}
            {children}
        </div>
    );
}

const MainContext = createContext<any>(undefined);

export const MainHotTag = () => {
    const pageSetting = useAtomValue(pageSettingAtom);
    const queryClient = useQueryClient()

    const data = ['태그1']
    const lists: any[] = useQueries(
        data?.map((arr : string,i : number)=>({
            queryKey : ['Get-Main-Content-List-Key','Main-Tag-List-Key',arr],
            queryFn : () => fetch(arr)
        }))
    )
    
    const fetch = async(tag : string) => {
        return await AxiosIns.get(`/api/getPostListByTag/` + tag + '/0?pageSize=1&page=0')
        .then(res=>res.data)
        .catch(err=>console.log(err))
    }

    return(
        <div className={styles.partContent}>
            <div className={cx('hotTag','flex-row','scrolling')}>
                {lists.map((list,i)=>(
                    (data[i])&&
                    <CustomLink key={data[i]} to={pageSetting.tag + "?tag=" + data[i]} className={cx('tagRowContainer','flex-column')}>
                        <div className={cs('Tags')}>#{data[i]}</div>
                        <div className={cx('hot-tag-feed-count')}></div>
                    </CustomLink>
                ))}
            </div>
        </div>
    );
}

export const ContentComp = ({title,list,category} : {title : string,list : any,category? : number}) =>{
    const scrollRef = useRef<HTMLDivElement>(null);
    return (
        <div className={cx('flex-column')} style={{width : "100%",position:"relative"}}>
            {title&&(<p className={cx("mainContentTitle")}>{title}</p>)}
                <LeftArrow className={cx("slick-prev","row-container")} onClick={()=>{
                    if (scrollRef.current) scrollRef.current.scrollBy(-Math.max(scrollRef.current.getBoundingClientRect().width,0),0);
                    }}/>
                    
                    {(list.isLoading)?
                    <div style={{width : '100%' , height : '100%',position:"relative"}}
                    className={cx('mainContentContainer',"row-contianer","flex-row","noscrolling")}
                    ref = {scrollRef}>
                    {new Array(7).fill(1).map((_arr,i)=>(
                        <ContentRowSkel key={i}/>
                    ))}
                    </div>
                    :
                    <div style={{width : '100%' , height : '100%',position:"relative"}}
                        className={cx('mainContentContainer',"row-contianer","flex-row","noscrolling")}
                        ref = {scrollRef}>
                            {list?.data?.map((arr : PropsData,i : number)=>(
                                <ContentRow arr={arr} key={arr.id} category={category}/>
                            ))}
                    </div>}
                <RightArrow className={cx("slick-next","row-container")}
                onClick={()=>{if (scrollRef.current) scrollRef.current.scrollBy(Math.min(scrollRef.current.getBoundingClientRect().width,scrollRef.current.scrollWidth),0);}}
                />
        </div>
    );
}


const TopFeedMenu = ({arr} : {arr : PropsData}) =>{
    const pageSetting = useAtomValue(pageSettingAtom);
    const setObject = useSetAtom(MenuObjectAtom);

    return (
        <div className={cx('menuSub',"flex-column")}>
            <hr/>
            <p>이 피드</p>
            <CustomBtn className={cx('menuSubMenu','flex-row')}
            onClick={()=>{setObject(<ShareAPI title={arr.title} text={arr.content} targetUrl={process.env.REACT_APP_BACK_SERVER_DOMAIN + pageSetting.list + '?page=' + arr.id}/>);}}
            >
                <ShareBtn width='20' height='20' fill="inherit" stroke="inherit"/>
                <p>공유</p>
            </CustomBtn>
        </div>
    );
}

const ContentRow = ({arr,category} : {arr : PropsData,category? : number}) => {
    const [pageSetting,] = useAtom(pageSettingAtom);
    const [windowState,] = useAtom(WindowStateAtom);

    return(
        <CustomLink to={`${pageSetting.list}?${category?`category=${category}&`:""}page=${arr.id}`}
        className={styles.mainRowContainer + ' row-container flex-column'} style={{backgroundImage : `url('${arr.imageLinks?.filter((e : any)=>e)[0]}')`}}
        >
            <MinimalRow arr={arr} menu={<TopFeedMenu arr = {arr}/>} cStyle={{title : {fontSize : '20px'} , writer : {fontSize : '16px'}}}/>
        </CustomLink>
    );
}
const ContentRowSkel = () => {
    return(
        <div className={styles.mainRowContainer + ' row-container flex-column skel-draw'}>
            <MinimalRowSkel/>
        </div>
    );
}


export const MainBanner = () =>{
    const banner :Array<string> 
    = [
        banner1,
        banner2,
        banner3,
        banner4,
    ]
    const navigate = useNavigate();
    const link : any=[
        '/?page=57',
        '/?page=57',
        '/?page=57',
        '/?page=57',
        '/list?category=15&page=57'
    ]
    const [random,setRandom] = useState<number>(Math.round(Math.random() * 4));

    return (
        <div className = {cx('imageContainer','row-container')}>
            <img src={banner[random]} width = '100%' height = '100%' alt="배너"></img>
        </div>
    );
}
/* style={{gridColumn : size.column,gridRow : size.row}} 
style={{width : size?.width , height : size?.height}}*/
export default MainPage;