import React, { useContext } from "react";
import { notiContext } from ".";
import { NotiPropsData } from "contents/func/type/typeinter";
import { DateUnit } from "contents/func/unit";
import styles from "./notification.module.css";
import DOMPurify from "dompurify";
import { useAtomValue } from "jotai";
import { pageSettingAtom } from "contents/state/state";
import { CustomClose, CustomLink } from "contents/func/anim/custom";

export const NotiRow = () => {
    const {arr} : {arr : NotiPropsData} = useContext(notiContext);
    const pageSetting = useAtomValue(pageSettingAtom);

    return (
        <CustomLink to = {`${pageSetting.list}?page=${arr.postId}`} className={styles.container + " row-container flex-column"}>
            <CustomClose/>
            <div className={styles.content + " flex-row"}>
                <RowFollow/>
            </div>
            <div className={styles.regdate}>{DateUnit(arr.alarmDate)}</div>
        </CustomLink>
    );
}
export const NotiRowSkel = () => {
    return (
        <div className={styles.container + " row-container flex-column"}>
            <div className={styles.content + " flex-row skel-draw"} style={{width : '95%',height : '70px' , margin : '8px'}}>
            </div>
            <div className={styles.regdate + ' skel-draw'} style={{width : '95%',height : '20px'}}></div>
        </div>
    );
}

const RowFollow = () =>{
    const {arr} : {arr : NotiPropsData} = useContext(notiContext);

    return (
    <div className={styles.container}>
        <div className={styles.content + " flex-row"}>
            <strong className={styles.nickname}>{arr.senderNickname}</strong>
            &nbsp;님이 내 피드에 댓글을 남기셨어요.
        </div>
        <div className={styles.myContent}>{arr.postTitle}</div>
        <div dangerouslySetInnerHTML={{__html : DOMPurify.sanitize(arr.commentContent)}}  className={styles.subContent}></div>
    </div>);
}

const RowFollowUploaded = () =>{
    const {arr} : {arr : NotiPropsData} = useContext(notiContext);

    return(<div className={styles.container}>
        <div className={styles.content + " flex-row"}>
            <strong className={styles.nickname}>{arr?.senderNickname}</strong>
            &nbsp;님이
            <strong className={styles.nickname}>#태그</strong>
            태그에 피드를 남기셨어요.
        </div>
        <div className={styles.subContent}>
            "{arr.commentContent}"
        </div>
    </div>);
}