import React, { createContext, Fragment, useContext, useState } from "react";
import { Section } from "contents/func/section/section";
import { useQueries, useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NormalMapProps, PropsData } from "contents/func/type/typeinter";
import { WindowStateAtom, pageSettingAtom } from "contents/state/state";
import { take } from "lodash";
import recomStyle from "./css/recom.module.css";
import cn from "classnames/bind";
import { useAtom } from "jotai";
import { AxiosIns } from "contents/func/network/axios";
import mainStyle from "contents/main/main.module.css";
import colStyle from "contents/post/list/css/colRow.module.css";
import { HeartBtn, MsgBtn } from "icons";
import { getUnit } from "contents/func/unit";
import { CustomBtn } from "contents/func/anim/custom";
import MinimalRow, { MinimalRowSkel } from "../list/minimal";
import { CollectedMap, ListHeader } from "../list";
import { ColRow } from "../list/colRow";
import { FeedSetting } from "contents/setting";

const recomContext = createContext<any>(null);

const RecomPage = () => {
    const [page,setPage] = useState<number>(0);
    const pageMax = 5;
    const [searchParam,setSearchParams] = useSearchParams();
    const category = searchParam.get('category')
    const fetchList = ['feed','tags','users'];
    const list = useQueries(
        fetchList.map((tagList : string,i : number)=>({
            queryKey : ['GetMainContentListKey','Main-Tag-List-Key',tagList],
            queryFn : () => (category)&&fetch(tagList)
        }))
    )
    const cx = cn.bind(recomStyle);
    const fetch = async(string : string) => await AxiosIns.get(`/api/getPostByList/0?pageSize=${pageMax}&categoryId=${category}`).then(res=>res.data);
    return (
        <div className={cx("feedPage",'flex-column')}>
            <ListHeader/>
            <Section menus={['추천 피드','태그','설정']} callback = {(_e : Event,i : number)=>setPage(i)} title={'추천'}/>
            <recomContext.Provider value={{list}}>
                {
                    {
                        0: <CollectedMap className={cx("recomColMap","scrolling")} row={1} list={{isSuccess : list[0].isSuccess,data:{pages:[list[0]]}}} />,
                        1: <></>,
                        2 : <FeedSetting/>
                    }[page as number]
                }
            </recomContext.Provider>
        </div>
    );
}

export default RecomPage;