import { getDateUnitWithTime, getUnit } from "contents/func/unit";
import DOMPurify from "dompurify";
import {AddBtnAlt, BookmarkBtn, BookmarkEmptyBtn,HeartBtn,HeartFillBtn} from "icons";
import {Link, useNavigate, useSearchParams } from "react-router-dom";
import React, { createContext,useEffect,useRef,useState } from "react";
import { BigMenuObjectAtom, ImageMenuAtom, ImageTargetAtom, ImageUIAtom, MenuObjectAtom, MenuTargetAtom, WindowStateAtom, pageSettingAtom } from "contents/state/state";
import { CommentModule, CommentView, CommentWritePage,} from "./comments";
import { TagList, TagListSkel } from "contents/func/tag/taglist";
import { SlickComp } from "contents/func/img/slick/slickOpt";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosIns } from "contents/func/network/axios";
import { CustomBtn, CustomImg } from "contents/func/anim/custom";
import TagStyles from "contents/func/tag/tags.module.css";
import ViewStyles from "./css/view.module.css";
import interStyles from "contents/interface/css/interface.module.css";
import listStyles from "contents/post/list/css/row.module.css";
import DynamicMeta from "dynamicMeta";
import { MobileView } from "contents/interface/mobile";
import { ShareComp } from "contents/share/share";
import TempImg from 'icons/user/logo.png';
import { useAtom, useSetAtom } from "jotai";
import cn from "classnames/bind"
import { RoleParser } from "contents/user/info/userInfo";
import { VideoControl } from "contents/video/custom";
import { HlsView } from "contents/video/hls";
import { FeedMenu } from "./manipulate";

export const commentContext = createContext();
const cx = cn.bind(ViewStyles);
const cl = cn.bind(listStyles);

export const ContentView = ()=>{
    const [searchParam,setSearchParam] = useSearchParams();
    const page = searchParam.get('page');
    const category = searchParam.get('category');
    const navigate = useNavigate();
    const [bookmarked,setBookmarked] = useState(false);
    const setTarget = useSetAtom(MenuTargetAtom);
    const setImgObject = useSetAtom(ImageMenuAtom);
    const setImgTarget = useSetAtom(ImageTargetAtom);
    const setController = useSetAtom(ImageUIAtom);
    const [pageSetting,] = useAtom(pageSettingAtom);
    const [windowState,] = useAtom(WindowStateAtom);
    const controlRef = useRef();
    const queryClient = useQueryClient();
    const [hasLiked,SetHasLiked] = useState(false);

    const {data , isLoading , isSuccess} = useQuery({
        queryKey : ['Get-View-Keys',page],
        queryFn : ()=>AxiosIns.get(`/api/getPostByPostId/${page}`),
        select : (res)=>res.data,
    })

    const LikeIncrement = useMutation({
        mutationKey : ['PostCommentIncKey',data?.id],
        mutationFn : ()=>AxiosIns.post('/api/post/LikeCount/increment',{
            receiverId : data.writerId,
            receiverNick : data.writerNick,
            postId : data.id,
            postTitle : data.title,
            currentLikeCount : data.likeCount
        }),
        onMutate : ()=>{SetHasLiked(e=>!e);}
    });
    /*const data = {title : 'fuck' , content : '야발'};
    const isLoading = false;
    const isSuccess = true;*/
    const setObject = useSetAtom(BigMenuObjectAtom);

    /*const comments = {isLoading : false , isSuccess : true , data : {pages:[{data : [
        {id : 1 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , commentsCount : 5 , regdate : '2018/12/12 00:00:00',comment : "fjdsklajfdlsa", depth : 1 , postId : data?.id ,parentId : null , steps : 0},
        {id : 2 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , regdate : '2018/12/13 00:00:00',comment : "fjdsklajfdlsa", depth : 2 , postId : data?.id ,parentId : 1 , steps : 1},
        {id : 3 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , regdate : '2018/12/13 03:00:00',comment : "fjdsklajfdlsa", depth : 2 , postId : data?.id ,parentId : 1 , steps : 2},
        {id : 4 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , commentsCount : 5 , regdate : '2018/12/12 00:00:00',comment : "fjdsklajfdlsa", depth : 1 , postId : data?.id ,parentId : null , steps : 0},
        {id : 5 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , regdate : '2018/12/13 00:00:00',comment : "fjdsklajfdlsa", depth : 2 , postId : data?.id ,parentId : 1 , steps : 1},
        {id : 6 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , regdate : '2018/12/13 03:00:00',comment : "fjdsklajfdlsa", depth : 2 , postId : data?.id ,parentId : 1 , steps : 2},
        {id : 7 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , commentsCount : 5 , regdate : '2018/12/12 00:00:00',comment : "fjdsklajfdlsa", depth : 1 , postId : data?.id ,parentId : null , steps : 0},
        {id : 8 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , regdate : '2018/12/13 00:00:00',comment : "fjdsklajfdlsa", depth : 2 , postId : data?.id ,parentId : 1 , steps : 1},
        {id : 9 , writer : 'admin' , org : '전북대학교 컴퓨터공학과' , regdate : '2018/12/13 03:00:00',comment : "fjdsklajfdlsa", depth : 2 , postId : data?.id ,parentId : 1 , steps : 2}
    ]}]}};*/

    useEffect(()=>{
        if (!page&&!category) navigate(pageSetting.main);
    },[page]);

    return (
        <div className={cx('viewContainer')}>
            <DynamicMeta title={data?.writerNick + ' : ' + data?.title||''} description={data?.content?.replace(/<[^>]*>?/g, '')||''} size={{width: "540px",height: "540px"}} url={Location.pathname} />
            {(isLoading)?
                <ContentViewSkel/>
            :
            (isSuccess&&data)?
            (<div className = {cx('rowContainer')}>
                <div className={cx('rowTopContainer',"row-container")}>
                    <div className={cl("list-row-title")}>{data.title}</div>
                    <TagList data={data} onClick={(e)=>navigate(pageSetting.tag + '?tag=' + e)}
                    className={{top : TagStyles.columnTagContainer,inner : TagStyles.columnTagContainer , tag : TagStyles.Tags}}/>
                </div>
                <div className={cl("list-row-text")}>
                    <SlickComp images={data.imageLinks} className={cx('imageContainer','row-container')}
                    cb={async(e,i)=>{
                        setImgTarget(e);
                        setImgObject({
                            image : data.imageLinks.map((imageLink)=>{
                                if (imageLink?.includes(process.env.REACT_APP_VIDEO_PREFIX||"")){
                                    setController(<VideoControl element={controlRef.current}/>);
                                    return <HlsView src={imageLink} ref={controlRef}/>;
                                }
                                else{
                                    return <CustomImg intersection={false} src={imageLink} alt={TempImg}/>
                                }
                            }),
                            index : i,
                        });
                    }}/>
                    <div className={cl("list-row-right","flex-column")}>
                        <div className = {cx("view-row-info","scrolling","row-container")}>
                            <div className = {cl("list-row-bottom-container","flex-row")}>
                                <div className = {cx('rowUserInfo'," flex-row")}>
                                    <div className = 'prof-container-middle'>
                                        <CustomImg src={data.writerProfile} alt="프로필" width = "100%" height="100%" altLink={TempImg}/>
                                    </div>
                                    <div className={cx('rowUserBottom'," flex-column")}>
                                        <Link to={pageSetting.userInfo + '?user=' + data.writerUserId} onClick={(e)=>e.stopPropagation()} className={cx("viewRowWriter")}>{data.writerNick}</Link>
                                        <div className={cx("viewRowOrg",'flex-row')}>
                                            {data.org||"소속 없음"}
                                            <RoleParser role = "USER"/>
                                        </div>
                                        <div className={cx('rowRegdate')}>{getDateUnitWithTime(new Date(data.createdAt))} 작성</div>
                                        {(data.updatedAt > data.createdAt)&&<div className={cx('rowRegdate')}>수정됨</div>}
                                    </div>
                                </div>
                            </div>
                            <div className = {cl("list-row-content","ql-editor")} dangerouslySetInnerHTML={{__html : DOMPurify.sanitize(data.content)}} style={{maxHeight : 'none'}}/>
                            <div className = {cx('rowBtnContainer',"flex-row")}>
                                <CustomBtn className={cl("list-row-like-btn","flex-row")} onClick = {()=>LikeIncrement.mutate()}>
                                    {(hasLiked)?
                                    <HeartBtn width="24px" height="100%"/>:<HeartFillBtn width="24px" height="100%"/>}
                                    <p className={cl("list-row-like-count")}>{getUnit(data.likeCount + Number(hasLiked))||'0'}</p>
                                </CustomBtn>
                                <CustomBtn className={cl("list-row-bookmark-btn","flex-row")} onClick={()=>(setBookmarked(!bookmarked))}>
                                    {(bookmarked)?<BookmarkBtn width="24px" height='100%' fill="var(--theme-first-color-rgb)" stroke="black" className="list-row-bookmark-svg"/>
                                    :<BookmarkEmptyBtn width="24px" height='100%' fill="inherit" className={cl("list-row-bookmark-svg")}/>}
                                </CustomBtn>
                                <ShareComp
                                title={data.title} text={data?.content?.substring(0, 32)}
                                url={process.env.REACT_APP_BACK_SERVER_DOMAIN + pageSetting.list + '?category=' + category + '&page=' + data.id}/>
                                <CustomBtn className={cl("list-row-bookmark-btn","flex-row")} onClick={e=>{setObject(<FeedMenu id={data.writerUserId} postId={data.id}/>); setTarget(e);}}>
                                    <p style={{fontWeight:'bold' , fontSize : '100%' , writingMode : "vertical-lr"}}>•••</p>
                                </CustomBtn>
                            </div>
                            <hr/>
                            <CommentView page={page} className={cx("only-pc")}/>
                            <CustomBtn>
                                <CommentView 
                                onTap={(e)=>{
                                    e.stopPropagation(); e.preventDefault();
                                    setTarget(e);
                                    setObject(
                                    <MobileView title="댓글">
                                        <CommentModule pageId={data.id}/>
                                    </MobileView>);
                                }}
                                page={page} preview={1} className={cx("only-mobile")}
                                />
                            </CustomBtn>
                            
                        </div>
                        {(!windowState.mobile)&&<CommentWritePage page={page}/>}
                    </div>
                </div>
            </div>)
            :
            (<div>
                게시글을 찾을수 없습니다..
            </div>)
            }
        </div>
    );
}

export const ContentViewSkel = () => {
    return (
        <div className = "list-row-container">
            <div className={cx('rowTopContainer',"row-container")}>
                <div className="skel-draw" style={{maxWidth : '100%',height : '54px'}}></div>
            </div>
            <div className="list-row-text">
                <div className="list-row-image-container skel-draw"/>
                <div className="list-row-right flex-column">
                    <div className = "list-row-info scrolling row-container">
                        <div className = "list-row-bottom-container flex-row">
                            <div className = {cx('rowUserInfo',"flex-row","skel-draw")} style={{height : '23px'}}/>
                            <div className={cx('rowRegdate','skel-draw')} style={{maxWidth : '240px',height : '23px'}}></div>
                        </div>
                        <div className = "list-row-content skel-draw" style={{height : '20%'}}/>
                        <hr/>
                    </div>
                </div>
            </div>
        </div>
    );
}
