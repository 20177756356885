export interface NormalMapProps{
    isLoading : boolean,
    pages:{
        data : PropsData[]
    }[]
}
export interface PropsData{
    
    id : number,
    writerUserId : string,
    writerId : Number,
    org : string,
    title : string,
    content? : string,
    writerNick: string
    createdAt : string,
    updatedAt : string,
    imageLinks? : string[],
    hashtags? : string[],
    likeCount : number,
    commentCount : number,
    private : boolean
}

export interface NotiMapProps{
    isLoading : boolean,
    data : {
        pages:{
            data : NotiPropsData[]
        }[]
    }
}
export interface NotiPropsData{
    sender : Number,
    receiver : Number,
    senderNickname : string,
    receiverNickname : string,
    postId : Number,
    postTitle : string,
    commentId : Number,
    commentContent : string,
    alarmDate : string,
    postContent : string
}


export interface CateList{
    isLoading : boolean,
    data : {
        pages : {
            data : CatePropsData[]
        }[]
    }
}
export interface CatePropsData{
    id : number , parent_id : number , category_name : string , create_at : string , category_description : string,depth : number
}

export interface ImageMenu{
    image : Array<string>,
    index : number
}

function getType(value : any){
    return Object.prototype.toString.call(value).slice(8,-1);
}
export function TypeCheck({type = "Any",value} : {type? : string , value : any}){
    return getType(value) == type;
}