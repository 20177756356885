import React, { createContext, Fragment, useContext, useEffect, useState } from "react"
import { CollectedBooleanAtom, NavbarLeftObjectAtom, RefreshQueryStateAtom, pageSettingAtom } from "contents/state/state";
import { CateRow, CateRowSkel} from "./row";
import { useNavigate } from "react-router-dom";
import { AddBtnAlt, LeftBtn, RightBtn } from "icons";
import { AxiosIns } from "contents/func/network/axios";
import styles from "./category.module.css";
import mainStyles from "contents/main/main.module.css";
import { useQuery, useQueryClient } from "react-query";
import { CustomBtn, CustomImg, CustomLink } from "contents/func/anim/custom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import cn from "classnames/bind";
import { useSetAlert } from "contents/func/toast/toast";
import _ from "lodash";
import TempImg from 'icons/user/logo.png';

const cx = cn.bind(styles);
const cs = cn.bind(mainStyles);
export const cateContext = createContext<any>(undefined);

export const SubCateMap = ({
    link = "/api/categories/getDepth1Categories" ,title , take}
    :{link? : string , title? : string , take? : number}) =>{
    const queryClient = useQueryClient();
    const Alert = useSetAlert();
    const [,setQueryToRefresh] = useAtom(RefreshQueryStateAtom);
    const {data,isLoading} = useQuery(['Get-Cate-List-Key'],async()=>
        await AxiosIns.get(link).then(res=>res.data),
        {onError : (err)=>{
                console.log(err);
                Alert('서버에 오류가 생겼어요😢')
        }}
    )/*
    const data = [
        {
            categoryId : 2,
            categoryName : "테스트1",
            categoryDescription : "테스트1",
            depth : 1,
            parent_id : 0,
            createdAt : Date.now(),
            profile : "",
            children : [
                {
                    categoryId : 4,
                    categoryName : "테스트1",
                    categoryDescription : "테스트1",
                    depth : 2,
                    parent_id : 2,
                    createdAt : Date.now(),
                    children : [
                        {
                            categoryId : 8,
                            categoryName : "테스트1",
                            categoryDescription : "테스트1",
                            depth : 3,
                            parent_id : 4,
                            createdAt : Date.now(),
                            children : [
                                {
                                    categoryId : 16,
                                    categoryName : "테스트1",
                                    categoryDescription : "테스트1",
                                    depth : 4,
                                    parent_id : 8,
                                    createdAt : Date.now()
                                },
                            ]
                        },
                    ]
                },
            ]
        },
        {
            categoryName : "테스트1",
            categoryDescription : "테스트1",
            depth : 1,
            parent_id : 0,
            createdAt : Date.now()
        },
    ]
    const isLoading = false;*/
    const array = (take)?_.take(data,take):data;
    const {setPage} = useContext(cateContext);

    return (
            <div className={cx("middle","flex-column","scrolling")}>
                {(!take)&&
                <div className={cx("row-container","LeftMenuTitle","flex-row")}>
                    <CustomBtn className={cx("closeBtn","flex-row")} onClick={()=>setPage(0)}>
                        <LeftBtn width = "16" height = "16" />
                    </CustomBtn>
                    {title}
                    <CustomBtn className={cx("closeBtn","flex-row")}>
                        <AddBtnAlt width = "16" height = "16" />
                    </CustomBtn>
                </div>}
                {(isLoading)?
                (
                    new Array(7).fill(1).map((_arr,i)=>(
                        <CateRowSkel key={i}/>
                    ))
                ):(
                    (array?.length > 0)?
                    <React.Fragment>
                        <SubCate childEnabled={!Boolean(take)} p={array} className={cx(`subCateMenu`,'default')}/>
                    </React.Fragment>
                    :<div className="flex-column">
                        <p>등록된 카테고리가 없어요...</p>
                    </div>
                )}
            </div>
    );
}

/*
                {(isLoading)?
                (
                    new Array(7).fill(1).map((_arr,i)=>(
                        <CateRowSkel key={i}/>
                    ))
                ):(
                    (array?.length > 0)?
                    <React.Fragment>
                        <SubCate p={array} className={cx(`subCateMenu`,'default')}/>
                    </React.Fragment>
                    :<div className="flex-column">
                        <p>등록된 카테고리가 없어요...</p>
                    </div>
                )}
*/
const SubCate = ({p,className,childEnabled = true}:
    {p : any , className : string,childEnabled? : boolean}) => {
    const setObject = useSetAtom(NavbarLeftObjectAtom);
    const [pageSetting,] = useAtom(pageSettingAtom);
    const navigate = useNavigate();
    const collected = useAtomValue(CollectedBooleanAtom);
    
    return (
        <React.Fragment>
            <div className={cx('flex-row')}
            style={{justifyContent: 'center', fill : "var(--theme-first-color-rgb)" ,
            display : (className.includes('default'))?"none":"flex"
            }}>
                <div style={{transform : "rotate(90deg)"}}>
                    <RightBtn width = "16" height="16"></RightBtn>
                </div>
            </div>
            {p?.map((arr: any,i: any)=>(
                <div className={className} key={arr.categoryId}>
                    <CateRow
                    htmlFor = {cx(`subCate${arr.categoryId}`)}
                    p = {arr}
                    childEnabled = {childEnabled}
                    otherCb={()=>{
                        setObject(null);
                        navigate(pageSetting.list + `?category=` + arr.categoryId + ((collected)?"&collected=true":""));
                    }}/>
                    <input type="checkbox" id={`subCate${arr.categoryId}`} className={cx('subCateChecked')} hidden/>
                    <div className={cx(`subCateMenu`)}>
                        <SubCate p={arr.children} className={cx(`subMenu${arr.categoryId}`)}/>
                    </div>
                </div>
            ))}
        </React.Fragment>
);
}

const CatePage = () =>{
    const [page,setPage] = useState<number>(0);
    const pageSetting = useAtomValue(pageSettingAtom);

    return(
        <div className={cx('listPage','flex-column',"scrolling")}>
            <cateContext.Provider value={{page,setPage}}>
            {
                {
                    0:<CatePageMain/>,
                    1:<SubCateMap title="구독 카테고리"/>,
                    2:<SubCateMap title="최신 카테고리"/>,
                    3:<MainHotCategory/>
                }[page]
            }
            </cateContext.Provider>
        </div>
    );
}

export const CatePageMain = () =>{
    const {setPage} = useContext(cateContext);

    return (
        <Fragment>
            <CustomBtn onClick={()=>setPage(1)} className={cx("row-container","LeftMenuTitle","flex-row")}>
                구독한 카테고리
                <RightBtn width = "16" height = "16" />
            </CustomBtn>
            <div className={cx("LeftMenuContent")}>
                <SubCateMap take={5}/>
            </div>
            <CustomBtn onClick={()=>setPage(2)} className={cx("row-container","LeftMenuTitle","flex-row")}>
                최신 카테고리
                <RightBtn width = "16" height = "16" />
            </CustomBtn>
            <div className={cx("LeftMenuContent")}>
                <SubCateMap take={5}/>
            </div>
            <CustomBtn onClick={()=>setPage(3)} className={cx("row-container","LeftMenuTitle","flex-row")}>
                오늘의 카테고리🔥
                <RightBtn width = "16" height = "16" />
            </CustomBtn>
            <div className={cx('row-container')}><MainHotCategory/></div>
        </Fragment>
    );
}

export const MainHotCategory = () =>{
    const pageSetting = useAtomValue(pageSettingAtom);
    const Alert = useSetAlert();
    const {data,isLoading} = useQuery(['Get-Main-Content-List-Key','Get-Cate-List-Key'],async()=>
        await AxiosIns.get(`/api/categories/getDepth1Categories`).then(res=>res.data),
        {
            onError : (err)=>{
                Alert('서버에 오류가 생겼어요😢')
            }
        }
    )
    return (
        <div className={cs('partContent','flex-row','hotCategory')}>
            {(isLoading)?
                (
                    new Array(10).fill(1).map((_arr,i)=>(
                       <div key={i} className={cs('flex-column','hot-cate-container')}>
                            <div className={cs('prof-container-middle','skel-draw')} style={{marginBottom : 2}}></div>
                            <div className={cs('hot-cate-info-container','skel-draw')} style={{width : '75%',height : '18px'}}></div>
                       </div>
                    ))
                ):(
                    (data?.length > 0)?
                        data?.map((arr : any,i : number)=>(
                            <CustomLink key={arr.categoryId} to={pageSetting.list + `?category=` + arr.categoryId} className={cs('flex-column','hot-cate-container')}>
                                <div className={cs('prof-container-middle')}>
                                <CustomImg src="" width = '100%' height = "100%" altLink = {TempImg}/>
                                </div>
                                <div className={cs('hot-cate-info-container')}>
                                    <p  className={cs('hot-cate-title')}>{arr?.categoryName}</p>
                                </div>
                            </CustomLink>
                        ))
                    :<div className="flex-column">
                        <p>카테고리를 불러올수 없습니다.</p>
                    </div>
                )}
        </div>
    );
}

export default CatePage;