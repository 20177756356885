import imageCompression from "browser-image-compression";
import { TypeCheck } from "../type/typeinter";

const { AxiosIns } = require("./axios");

export const uploadConfig = {
    headers : {
        'Content-Type': 'multipart/form-data',
        'Authorization' : localStorage.getItem('userToken')
    },
}

/**
 * 
 * @param {*} e 파일의 Event
 * @param {*} pos 업로드할 파일의 위치
 * @param {*} column 업로드할 파일의 속성명
 * @param {*} cb 콜백 함수 (파일 링크 반환)
 * @returns 
 */
const ImageCompression = (file : File) =>
    new Promise(async(resolve,reject)=>{
    const httpRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const options = {
        maxSizeMB : 0.2,
        maxWidthOrHeight : 1920,
        useWebWorker : true,
    }
    try{
        const compressedFile = await imageCompression(file, options);
        imageCompression.getDataUrlFromFile(compressedFile)
            .then((res)=>resolve(res))
            .catch((err)=>reject(err));
    } catch(err){
        console.log(err);
    }
})
export const imageHandler = async(e : any,pos : string,column : string,cb? : Function,onMutate? : Function)=>{
    const array = new Array();
    const TargetFiles = [...(e.target.files||e.dataTransfer.files)];
    const formData = new FormData();

    for (var key in TargetFiles){
        await ImageCompression(TargetFiles[key])
        .then((res : any)=>{
            array.push(res);
            const changedFile = dataURLtoFile(res,TargetFiles[key].name);
            formData.append(column,changedFile);
        }).catch((err)=>console.log(err));
    }

    if(onMutate) onMutate(array);
    
    await AxiosIns.post(pos,formData,uploadConfig)
    .then((res : any)=>{
        if (cb&&TypeCheck({type : "Array",value : res})) cb(res.data)
    })
    .catch((e : Event)=>{console.log(e)});
    return;
}

export const fileHandler = async(e : any,pos : string,column : string,cb? : Function)=>{
    
    const TargetFiles = [...(e.target.files||e.dataTransfer.files)];

    const formData = new FormData();
    for (var key in TargetFiles){
        formData.append(column, TargetFiles[key]);
    }

    await AxiosIns.post(pos,formData,uploadConfig)
    .then((res : any)=>{
        if (cb&&TypeCheck({type : "Array",value : res})) cb(res.data)
    })
    .catch((e : Event)=>{console.log(e)});
    return;
}

const dataURLtoFile = (dataurl : string, fileName : string) => {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)![1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], fileName, {type:mime});
}