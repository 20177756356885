import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { listContext } from ".";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DateUnit, getUnit } from "contents/func/unit";
import { AddBtnAlt, BookmarkBtn, BookmarkEmptyBtn, CertBtn, DeleteBtn, EditBtn, HeartBtn, HeartFillBtn, UserBtn } from "icons";
import { BigMenuObjectAtom, CurrentUserAtom, MenuTargetAtom,PostModalObjectAtom,WindowStateAtom, pageSettingAtom } from "contents/state/state";
import { SlickComp } from "contents/func/img/slick/slickOpt";
import { TagList} from "contents/func/tag/taglist";
import { CustomBtn, CustomImg, CustomLink } from "contents/func/anim/custom";
import TagStyles from "contents/func/tag/tags.module.css";
import { useMutation, useQueryClient } from "react-query";
import { AxiosIns } from "contents/func/network/axios";
import { ShareComp } from "contents/share/share";
import { CommentModule} from "../view/comments";
import { MobileView } from "contents/interface/mobile";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import TempImg from 'icons/user/logo.png';
import rowStyle from "./css/row.module.css";
import cn from "classnames/bind";
import { RoleParser } from "contents/user/info/userInfo";
import Lottie from "lottie-web";
import { useSetAlert } from "contents/func/toast/toast";
import { FeedMenu } from "../view/manipulate";

const cx = cn.bind(rowStyle);
const StarJson = require("icons/post/star.json");

export const Row = ()=>{
    const {arr} = useContext(listContext);
    const setObject = useSetAtom(BigMenuObjectAtom);
    const setTarget = useSetAtom(MenuTargetAtom);
    const navigate = useNavigate();
    const [windowState,] = useAtom(WindowStateAtom);
    const [pageSetting,] = useAtom(pageSettingAtom);
    const [bookmarked,setBookmarked] = useState(false);
    const [searchParam,setSearchParam] = useSearchParams();
    const ParamHandler = (type : string,value : string) => setSearchParam((p)=>{p.set(type,value); return p;})
    const [hasLiked,SetHasLiked] = useState(false);
    const category = searchParam.get('category');
    const myId = useAtomValue(CurrentUserAtom);
    const LikeIncrement = useMutation(()=>AxiosIns.post('/api/post/LikeCount/increment',{
        idOfUser : myId?.userId,
        receiverId : arr.writerId,
        receiverNick : arr.writerNick,
        postId : arr.id,
        postTitle : arr.title,
        currentLikeCount : arr.likeCount
    }),{
        onMutate : ()=>{SetHasLiked(e=>!e); }
    });
    const likeRef = useRef<any>();
    const [lottie,setLottie] = useState<any>(null);

    useEffect(()=>{
        if (!hasLiked){
            likeRef.current?.addEventListener("click",()=>{
                setLottie(
                    Lottie.loadAnimation({
                    container : likeRef.current,
                    renderer : 'svg',
                    loop : false,
                    autoplay : true,
                    animationData : StarJson
                    })
                );
            });
        }
        return ()=>{
            lottie?.destroy();
            likeRef.current?.removeEventListener("click",()=>{
                setLottie(
                    Lottie.loadAnimation({
                    container : likeRef.current,
                    renderer : 'svg',
                    loop : false,
                    autoplay : true,
                    animationData : StarJson
                    })
                );
            });
        }
    },[hasLiked]);
    
    return (
        <div onClick={()=>{localStorage.setItem('scroll',window.scrollY.toString()); ParamHandler('page',arr.id);}}
        className = "list-row-container row-container">
            <div className="list-row-top-container flex-row">
                <div className="list-row-title">{arr.title}</div>
                <TagList data={arr} onClick={(e : string)=>navigate(pageSetting.tag + '?tag=' + e)}
                className={{top : TagStyles.columnTagContainer,inner : TagStyles.columnTagContainer , tag : TagStyles.Tags}}/>
            </div>
            <SlickComp images={arr.imageLinks} className={cx('list-row-image-container')}/>
            <div className = "list-row-bottom">
                <div className = "list-row-bottom-container flex-row">
                    <div className = "list-row-user-info flex-row">
                        <div className = "prof-container-small">
                            <CustomImg src={arr.writerProfile} alt="프로필" width = "100%" height="100%" altLink={TempImg}/>
                        </div>
                        <div className={cx("list-row-info-container","flex-column")}>
                            <div className="list-row-writer flex-row">
                                {arr.writerNick}
                                <RoleParser role="USER"/>
                            </div>
                            <div className="list-row-create_at">{DateUnit(arr.createdAt)}</div>
                        </div>
                    </div>
                    <div className = "list-row-button-container flex-row"  onClick={(e)=>(e.stopPropagation())}>
                        <CustomBtn className="list-row-like-btn flex-row" onClick = {()=>LikeIncrement.mutate()}>
                            {(hasLiked)?
                                <HeartBtn width="24px" height="100%"/>:<HeartFillBtn width="24px" height="100%"/>}
                            <p className="list-row-like-count">{getUnit(arr.likeCount + Number(hasLiked))||'0'}</p>
                        </CustomBtn>
                        <CustomBtn className="list-row-bookmark-btn flex-row" onClick={(e)=>{e.stopPropagation(); setBookmarked(!bookmarked);}}>
                            {(bookmarked)?<BookmarkBtn width="24px" height='100%' fill="var(--theme-first-color-rgb)" className="list-row-bookmark-svg"/>
                            :<BookmarkEmptyBtn width="24px" height='100%' className="list-row-bookmark-svg"/>}
                        </CustomBtn>
                        <ShareComp
                        title={arr.title} text={arr?.content?.substring(0, 32)}
                        url={process.env.REACT_APP_BACK_SERVER_DOMAIN + pageSetting.list + '?category=' + category + '&page=' + arr.id}
                        />
                        <CustomBtn className="list-row-bookmark-btn flex-row" onClick={e=>{setObject(<FeedMenu postId = {arr.id} id={arr.writerUserId}/>); setTarget(e);}}>
                            <p style={{fontWeight:'bold' , fontSize : '100%' , writingMode : "vertical-lr"}}>•••</p>
                        </CustomBtn>
                    </div>
                </div>
                <hr/>
                <div className = "list-row-bottom-comments flex-row">
                    <button className="list-row-comment-btn flex-row" onClick={(e)=>{
                    if (windowState.mobile){
                        e.stopPropagation(); e.preventDefault();
                        setTarget(e);
                        setObject(
                        <MobileView title="댓글">
                            <CommentModule pageId={arr.id}/>
                        </MobileView>);
                    }}}>
                        <AddBtnAlt height='12px' width="20px"/>
                        댓글 {getUnit(arr.commentCount)||'0'}개
                    </button>
                </div>
            </div>
        </div>
    );
}

export const SkelRow = () =>{
    return(
        <div className = "list-row-container row-container">
            <div className="list-row-top-container flex-row">
                <div className="list-row-title skel-draw" style={{height : '20px',marginBottom : '2px'}}></div>
                <div className={TagStyles.columnTagContainer + " skel-draw"} style={{height : '26px',marginBottom : '2px'}}/>
            </div>
            <div className={cx('list-row-image-container',"skel-draw")}/>
            <div className = "list-row-bottom">
                <div className = "list-row-bottom-container flex-row">
                    <div className = "list-row-user-info flex-row" style={{marginBottom : '2px'}}>
                        <div className = "prof-container-small skel-draw">
                        </div>
                        <div className={cx("list-row-info-container","flex-column")} style={{height : '40px'}}>
                            <div className="list-row-writer flex-row skel-draw" style={{height : '18px', width : '160px',marginBottom : '2px'}}/>
                            <div className="list-row-create_at skel-draw" style={{height : '18px', width : '160px'}}/>
                        </div>
                    </div>
                    <div className = "list-row-button-container flex-row skel-draw" style={{height : '28px'}}/>
                </div>
                <hr/>
                <div className = "list-row-bottom-comments flex-row">
                    <div className="list-row-comment-btn flex-row skel-draw"/>
                </div>
            </div>
        </div>
    );
}

