import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PostModalObjectAtom, SearchFormOnAtom, WindowStateAtom, pageSettingAtom } from "contents/state/state";
import { MenuBackWrapper } from "./wrapper";
import { LoadingBar } from "../func/anim/loading";
import { useQueryClient } from "react-query";
import { useAtom } from "jotai";
import { CustomBtn, CustomClose } from "contents/func/anim/custom";
import cn from "classnames/bind"
import interStyle from "./css/interface.module.css";
import { createPortal } from "react-dom";
import { SearchBtn } from "icons";

const cx = cn.bind(interStyle);

/**
 * 스크롤 리프레쉬 컴포넌트
 * @param {props} listRef 스크롤 리프리쉬 할 리스트
 * @returns 
 */
export const ScollInterFace = ({style,children,refreshQuery = [],pullSize = 32}) =>{
    const [StartY,setStartY] = useState(0);
    const [StartX,setStartX] = useState(0);
    const [pullDistance,setPullDistance] = useState(0);
    const [isPatching,setIsPatching] = useState(false);
    const [windowState,] = useAtom(WindowStateAtom);
    const queryClient = useQueryClient();
    const pullMax = Math.max(pullSize,196);
    const compare = (a,b) => (a>b)?Math.abs(a-b):Math.abs(b-a);

    const touchStart = (e)=>{
        if (!windowState.mobile) return;
        if (e.changedTouches) setStartY(e.changedTouches[0].clientY);
        else if (e.clientY) setStartY(e.clientY);
        if (e.changedTouches) setStartX(e.changedTouches[0].clientX);
        else if (e.clientX) setStartX(e.clientX);
    }
    const touchMove = (e)=>{
        if (!windowState.mobile) return;
        let moveY = 0;
        let moveX = 0;
        if (e.changedTouches) moveY = e.changedTouches[0].clientY;
        else if (e.clientY) moveY = e.clientY;
        if (e.changedTouches) moveX = e.changedTouches[0].clientX;
        else if (e.clientX) moveX = e.clientX;
        if (!isPatching && compare(moveX,StartX) < pullSize) {
            setPullDistance(Math.min(pullMax,moveY - StartY));
        }
    }
    const touchEnd = ()=>{
        if (!windowState.mobile) return;
        if (pullDistance > pullSize && window.scrollY < 1 && !isPatching) {
            setIsPatching(true);
            queryClient.invalidateQueries(refreshQuery);
            setPullDistance(pullSize/4);
            setTimeout(ResetCallback,2000);
            setStartY(0);
        }
    }
    const ResetCallback =() =>{
        let timeout = null;
        if (!windowState.mobile) return;
        if (queryClient.isFetching()){
            timeout = setTimeout(ResetCallback,2000);
        }else{
            clearTimeout(timeout);
            setIsPatching(false);
            setPullDistance(0);
        }   
    }

    useEffect(()=>{
        if (!windowState.mobile) return;
        window.addEventListener('touchstart', touchStart)
        window.addEventListener('touchmove', touchMove)
        window.addEventListener('touchend', touchEnd)
        return ()=>{
            window.removeEventListener('touchstart', touchStart)
            window.removeEventListener('touchmove', touchMove)
            window.removeEventListener('touchend', touchEnd)
        }
    },[pullDistance,refreshQuery]);

    return (
        <React.Fragment>
            <div className={cx("loading-background","only-mobile")}>
                <LoadingBar autoPlay={isPatching} style={{...style , height : 64 ,
                    opacity : (window.scrollY < 1 && pullDistance > 0)?1:0,
                    transform : `translate(-50%,${(window.scrollY < 1 && pullDistance > 0)?pullDistance:-1 * pullSize}px)`}}/>
            </div>
            {children}
        </React.Fragment>
    );
}

export const ContentsView = ({children}) =>{
    //const navigate = useNavigate();
    const [windowState,] = useAtom(WindowStateAtom);
    //const [currentData,setCurrentData] = useAtom(CurrentContentAtom);
    const [searchParam,setSearchParam] = useSearchParams();
    const page = searchParam.get('page');
    const ParamHandler = (type) => type?.map((arr)=>setSearchParam((p)=>{p.delete(arr); return p;}))

    return (
        ((page&&!windowState.mobile)?
        <MenuBackWrapper isOpened = {true} cb = {()=>{ParamHandler(['page','ind']);}}>
        <div className={cx("view-module-fixed",'flex-row')}>
            <div className={cx("view-module-modal","scrolling")} onClick={(e)=>e.stopPropagation()}>
                <CustomClose onClick={()=>ParamHandler(['page','ind'])}/>
                {children}
            </div>
        </div>
        </MenuBackWrapper>:<></>)
    );
}

export const Modal = () =>{
    const [postModal,setPostModal] = useAtom(PostModalObjectAtom);

    return (
        <MenuBackWrapper isOpened = {postModal} cb = {()=>setPostModal(null)}>
        <div className={cx("view-module-fixed",'flex-row')}>
            <div className={cx("view-module-modal-etc","scrolling")} onClick={(e)=>e.stopPropagation()}>
                {postModal}
            </div>
        </div>
        </MenuBackWrapper>
    );
}

export const BottomCircleBtn = ({object , className = '',cb = {}}) => {
    return (
        <CustomBtn className={cx("bottom-circle-btn","flex-row",'row-container',className)} onClick={(e)=>{if (cb) cb(e);}}>
            {object}
        </CustomBtn>
    );
}

export const TopSearchForm = () =>{
    const [searchOn,setSearchOn] = useAtom(SearchFormOnAtom);
    return createPortal(
        <MenuBackWrapper className={cx("only-mobile-flex")} isOpened = {searchOn} cb={()=>{setSearchOn(false)}} blackBack={true}>
            <div className={cx("mobile-search-bar",'flex-column')}
            style={{transform : (searchOn)?'translateY(0px)':'translateY(-64px)'}}
             onClick={(e)=>{e.stopPropagation();}}>
                <div className = {cx("mobile-search-form",'flex-row')}>
                    <input type='text' tabIndex={1} className = {cx("mobile-search-input")}></input>
                    <SearchBtn height='100%' width = "18px" fill="inherit"/>
                    <hr/>
                </div>
            </div>
        </MenuBackWrapper>,document.querySelector("#portal")
    );
}

export const DropdownDiv = ({children , callback , className="" , style = {} , onClick}) => {
    const dropRef = useRef();
    const dropdownCb = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (callback) callback(e);
    },[]);
    const dragoverCb = useCallback((e)=>{
        e.preventDefault();
        e.stopPropagation();
        return;
    },[]);

    useLayoutEffect(()=>{
        dropRef.current?.addEventListener('drop',dropdownCb);
        dropRef.current?.addEventListener('dragover',dragoverCb);
        return ()=>{
            dropRef.current?.removeEventListener('drop',dropdownCb);
            dropRef.current?.removeEventListener('dragover',dragoverCb);
        };
    },[]);

    return(
        <div className={className} style={{...style}} ref={dropRef} onClick={onClick}>
            {children}
        </div>
    )
}