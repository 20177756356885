import React, { ReactNode, useEffect, useRef, useState } from "react";
import sectionStyle from "./css/section.module.css";
import { CustomBtn } from "../anim/custom";
import cn from "classnames/bind";

const cx = cn.bind(sectionStyle);

export const Section = ({menus = [] , callback , className , title , style}
: {menus : Array<string> , callback? : Function , className? : string , title? : string , style? : object}) =>{
    const [targetRef,setTargetRef] = useState<HTMLElement|null>(null);
    const [index,setIndex] = useState(0);
    const firstRef = useRef<any>();

    useEffect(()=>{
        setTargetRef(firstRef?.current);
        return ()=>{
            setTargetRef(null);
        }
    },[]);

    return(
        <div className={cx('recomContainer','flex-column',className)} style={{...style}}>
            <div className={cx("recomTitle")}>
                {title!}
            </div>
            <div className={cx('recomContent','flex-row')}>
                {menus.map((arr,i)=>(
                    <React.Fragment key={i}>
                        <CustomBtn className={cx('section','flex-column','section' + i,{select : (index == i)})} key={arr}
                        onClick={(e)=>{setTargetRef(e.target as HTMLElement); setIndex(i); if (callback){callback(e,i);}}} ref={(el)=>{if (i==0) firstRef.current = el}}>
                            {arr}
                        </CustomBtn>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}