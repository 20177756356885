import { AnimatePresence , motion } from "framer-motion";
import React from "react";
import styles from "./css/motion.module.css";
import cn from "classnames/bind";

const cx = cn.bind(styles);

export const Motion = (
{className,children,PageKey}
: {className : string,children : React.ReactChild,PageKey : any}) =>(
        <AnimatePresence>
            <motion.div
            transition={{duration : 0.5 , ease: "easeOut"}}
            initial = {{opacity : 0}}
            animate = {{opacity : 1 , transition:{delay : 0.25}}}
            key = {PageKey}
            exit = {{opacity : 0}}
            className = {className}
            >
                {children}
            </motion.div>
        </AnimatePresence>
);

export const SlideMotion = (
{className,children,PageKey,direction = 1}
: {className : string,children : React.ReactChild,PageKey : any , direction? : number}) =>(
        <AnimatePresence>
            <motion.div
            transition={{ease: "easeOut"}}
            initial = {{opacity : 0}}
            animate = {{x : 0 , opacity : 1}}
            key = {PageKey}
            exit = {{opacity : 0}}
            className = {cx(className,'motion-div')}
            >
                {children}
            </motion.div>
        </AnimatePresence>
);