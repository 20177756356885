import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from "react-query";
import {RecoilRoot} from 'recoil';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'jotai';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { AlertList } from 'contents/func/toast/toast';

const queryClient = new QueryClient({
      queries: {
        refetchOnWindowFocus : false,
        useErrorBoundary: true,
        retry : 1,
        retryDelay : 5000,
        suspense: true,
     },
     mutations : {
        useErrorBoundary: true,
        onMutate : (res) => console.log(res),
        onError : (err) => console.log(err),
     }
  });
const element = 
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client = {queryClient}>
        <Provider>
          <HelmetProvider>
            <AlertList>
              <App />
            </AlertList>
          </HelmetProvider>
        </Provider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
;

const DomNode = document.getElementById('root');

if (DomNode.hasChildNodes()){
  ReactDOM.hydrateRoot(DomNode,element);
} else{
  const root = ReactDOM.createRoot(DomNode);
  root.render(element);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
