import { useEffect, useState } from "react";
import ReactDom from "react-dom";
import { MenuBackWrapper } from "./wrapper";
import { AlertObjectAtom, AlertTargetAtom, NavbarLeftObjectAtom, NavbarLeftTargetAtom, NavbarLeftTitleAtom, WindowStateAtom } from "contents/state/state";
import { useAtom, useAtomValue } from "jotai";
import cn from "classnames/bind"
import interStyle from "./css/interface.module.css";

const cx = cn.bind(interStyle);
export const NavLeftMenu = () =>{
    const target = useAtomValue(NavbarLeftTargetAtom);
    const [object,setObject] = useAtom(NavbarLeftObjectAtom);
    const [pos,setPos] = useState(null);
    const reset = () =>{
        setObject(null);
        setPos(null);
    }
    const io = new IntersectionObserver((entries)=>{
        entries.forEach((entry)=>{
            if (entry.isIntersecting){
                setPos(entry.boundingClientRect);
            } else {
                reset();
            }
        })
    });
    
    useEffect(()=>{
        if (target?.target) io.observe(target.target);
        return()=>{
            if (target?.target) io.unobserve(target.target);
            setPos(null);
        }
    },[target]);

    return ReactDom.createPortal(
        <MenuBackWrapper isOpened = {object} cb = {()=>reset()}>
            <div className={cx("smallmenu-navleft-container")} onClick={e=>e.stopPropagation()}
            style={{
            visibility : (object)? 'visible' : 'hidden',
            width : (object)?`480px`:'0px',
            height : `100svh`,
            opacity : (object)?'1':'0',
            transform : `translate3d(${pos?.right}px,
            0,0)`
            }}
            >
                <div className={cx("smallmenu-navleft-border")}
                style={{
                opacity : (object)?'1':'0',
                transform : `translate3d(0px,
                ${pos?.y - pos?.height / 2}px,0)`
                }}
                ></div>
                <div className={cx("smallmenu-navleft-contents","scrolling","row-container-shadowed")}>
                    {object}
                </div>
            </div>
        </MenuBackWrapper>
    ,document.querySelector('#portal'));
}


export const NavAlertMenu = () =>{
    const target = useAtomValue(AlertTargetAtom);
    const [object,setObject] = useAtom(AlertObjectAtom);
    const [pos,setPos] = useState(null);
    const reset = () =>{
        setObject(null);
        setPos(null);
    }
    const io = new IntersectionObserver((entries)=>{
        entries.forEach((entry)=>{
            if (entry.isIntersecting){
                setPos(entry.boundingClientRect);
            } else {
                reset();
            }
        })
    });
    
    useEffect(()=>{
        if (target?.target) io.observe(target.target);
        return()=>{
            if (target?.target) io.unobserve(target.target);
            setPos(null);
        }
    },[target]);

    return ReactDom.createPortal(
        (<MenuBackWrapper isOpened = {object} cb = {()=>setObject(null)}>
            <div className={cx("smallmenu-alert-container")} onClick={e=>e.stopPropagation()}
            style={{
            visibility : (object)? 'visible' : 'hidden',
            width : (object)?`480px`:'0px',
            opacity : (object)?'1':'0',
            transform : `translate3d(${pos?.x - 420}px,${pos?.y + 72}px,0)`
            }}
            >
                <div className={cx("smallmenu-alert-border")}
                style={{
                opacity : (object)?'1':'0',
                transform : (object)?`translate3d(416px,-36px,0)`:`translate3d(0px,-36px,0)`
                }}
                ></div>
                <div className={cx("smallmenu-navleft-contents","scrolling","row-container-shadowed")}>
                    {object}
                </div>
            </div>
        </MenuBackWrapper>)
    ,document.querySelector('#portal'));
}