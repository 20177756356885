import { CustomBtn } from "contents/func/anim/custom";
import Cropper from "react-cropper";
import { Fragment, useEffect, useRef, useState } from "react";
import cn from "classnames/bind";
import editorStyle from "./editor.module.css";
import { CloseBtn } from "icons";
import { ImageEditorLinkAtom } from "contents/state/state";
import { useAtom } from "jotai";

const cx = cn.bind(editorStyle);

export const ImgEditor = ({cb} : {cb:Function}) =>{
    const editorRef = useRef<any>();
    const [link,setLink] = useAtom(ImageEditorLinkAtom);
    const [vlink,setVLink] = useState<string|null>(null);
    const [page,setPage] = useState('main');

    const onCrop = () => {
        const cropper = editorRef.current?.cropper;
        console.log(cropper);
        const cropped = cropper.getCroppedCanvas()?.toDataURL()
        setVLink(cropped);
        setPage("main");
    }

    const onReset = () =>{
        const cropper = editorRef.current?.cropper;
        setVLink(null);
    }

    const setRatio = (ratio : number) =>{
        const cropper = editorRef.current?.cropper;
        cropper.setAspectRatio(ratio)
    }

    const setCropMode = () =>{
        const cropper = editorRef.current?.cropper;
        cropper.crop();
    }
    
    const cropMoveEnd = () =>{
        const cropper = editorRef.current?.cropper;
        cropper.setCanvasData({
            left : 0,
            top : 0,
            width : Infinity,
        });
    }
    
    return (
        <div className={cx('image-editor','flex-column')}>
            <div className={cx("image-form")}>
                <Cropper
                    src={vlink||link||""}
                    viewMode={3}
                    background={false}
                    dragMode = "move"
                    autoCrop = {false}
                    autoCropArea = {1}
                    guides={false}
                    ref = {editorRef}
                    onMouseUp={cropMoveEnd}
                />
            </div>
            <div className={cx("navbar-temp")}>
                <div className={cx("top-temp")}>
                    <div className={cx("top-navbar",'flex-row')}>
                        {
                            {
                                "main":
                                <Fragment>
                                    <CustomBtn onClick={()=>{if (cb) cb(null);}} className={cx("navbar-buttons",'flex-row')}>
                                        취소
                                    </CustomBtn>
                                    <CustomBtn onClick={()=>{if (cb) cb(vlink);}} className={cx("navbar-buttons",'flex-row')}>
                                        확인
                                    </CustomBtn>
                                </Fragment>,
                                'crop': 
                                <Fragment>
                                    <CustomBtn onClick={()=>setPage("main")} className={cx("navbar-buttons",'flex-row')}>
                                        취소
                                    </CustomBtn>
                                    <CustomBtn onClick={onCrop} className={cx("navbar-buttons",'flex-row')}>
                                        확인
                                    </CustomBtn>
                                </Fragment>
                            }[page]
                        }
                        
                    </div>
                </div>
                <div className={cx("bottom-temp")}>
                    <div className={cx("bottom-navbar",'flex-row')}>
                        <CustomBtn className={cx("navbar-buttons",'flex-row')} onClick={()=>{setCropMode(); setPage('crop');}}>크롭</CustomBtn>
                        <CustomBtn className={cx("navbar-buttons",'flex-row')} onClick={()=>{}}>색상 조정</CustomBtn>
                        <CustomBtn className={cx("navbar-buttons",'flex-row')} onClick={()=>{onReset(); setPage('main');}}>초기화</CustomBtn>
                    </div>
                    <div className={cx("bottom-subbar",'flex-row')}>
                        {
                            {
                                'crop' : 
                                <Fragment>
                                    <CustomBtn className={cx("navbar-buttons",'flex-row')} onClick={()=>{setRatio(1/1);}}>1:1</CustomBtn>
                                    <CustomBtn className={cx("navbar-buttons",'flex-row')} onClick={()=>{setRatio(4/3);}}>4:3</CustomBtn>
                                    <CustomBtn className={cx("navbar-buttons",'flex-row')} onClick={()=>{setRatio(16/9);}}>16:9</CustomBtn>
                                </Fragment>,
                                'saturation':
                                <></>
                            }[page]
                        }
                    </div>
                </div>
                
            </div>
        </div>
    )
}
