import { useInfiniteQuery, useMutation, useQueries, useQuery, useQueryClient } from "react-query";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { NormalMapProps } from "../type/typeinter";
import { AxiosIns } from "../network/axios";
import { CollectedMap, ListHeader, NormalMap } from "contents/post/list";
import React, { createContext, Fragment, useContext, useEffect, useState } from "react";
import { AddBtn, AddBtnAlt, HeartBtn, HeartFillBtn, RightBtn, SearchBtn } from "icons";
import styles from "./tags.module.css";
import { TagList } from "./taglist";
import { WindowStateAtom, pageSettingAtom } from "contents/state/state";
import { useAtom, useAtomValue } from "jotai";
import ColStyles from "contents/post/list/css/colRow.module.css";
import { Section } from "../section/section";
import { MainHotTag } from "contents/main/main";
import { ContentView } from "contents/post/view/view";
import cn from "classnames/bind";
import DynamicMeta from "dynamicMeta";
import { CustomBtn } from "../anim/custom";

const cx = cn.bind(styles);
const tagContext = createContext<any>(undefined);

const TagListPage = ({pageMax = 10}) =>{
    const [searchParam,setSearchParam] = useSearchParams();
    const ParamDelHandler = (type : string) => setSearchParam({[type] : searchParam.get(type)||'0'})
    const tag = searchParam.get('tag');
    const page = searchParam.get('page');
    const windowState = useAtomValue(WindowStateAtom);
    const list = useInfiniteQuery(['Get-Tag-Search-Key',tag],
    async({pageParam = 0})=>await AxiosIns.get(`/api/getPostListByTag/` + tag + '/' + pageParam),{
        getNextPageParam: (lastPage, pages) => {
            if (lastPage?.data?.length >= pageMax) return pages.length + 1;
            else return undefined;
        }
    });

    return(
        <div className={cx('list-page')}>
            <DynamicMeta title={`#${tag}`} description={`#${tag}를 추가한 게시글`} size={{ width: "540px", height: "540px" }} url={"/tag"} />
            {(page&&windowState.mobile)?
            (<ContentView/>)
            :
            <Routes>
                <Route path = '/' element={<NormalMap className="list-row-page"list={list}/>}/>
                <Route path = '/collected' element={<CollectedMap row={4} className={ColStyles.collectedRow + " flex-row"} list={list}/>}/>
            </Routes>
            }
        </div>
    );
}

export const TagListHeader = () =>{
    //const tagHasSub = useQuery('Tag-Has-Subscribe-Key',()=>AxiosIns.get(''),{onSettled : (res)=> {return res?.data;}});
    const [tagHasSub,setTagHasSub] = useState<boolean>(false);
    const [searchParam,setSearchParam] = useSearchParams();
    const tag = searchParam.get('tag');
    const queryClient = useQueryClient();
    
    const tagFetch = useQuery(['Get-My-Tag-Key'],()=>AxiosIns.get('/api/restrict/user/tagsFindByUserId').then((res)=>(res.data)),{
        onSuccess : (res)=>{
            console.log(res);
            if (res) {
                const filter = res?.filter((t : string)=>(t===tag));
                setTagHasSub(Boolean(filter.length > 0))
            }
        }
    });
    const tagSub = useMutation(()=>AxiosIns.post(`/api/restrict/user/subscribe/tagByName/${tag}`),{
        onMutate : ()=>setTagHasSub(e=>!e),
        onSuccess : ()=>{queryClient.removeQueries(['Get-My-Tag-Key'])}
    });

    return(
        (tag)?
        <Fragment>
            <input type="checkbox" id={styles.subToggle} onChange={()=>{
                if(tagFetch.isSuccess){tagSub.mutate();}}}
            checked = {Boolean(tagHasSub)} hidden></input> 
            <label htmlFor={styles.subToggle} className={styles.subToggleSwitch + ' flex-column'}>
                {(tagHasSub)?
                <div className={styles.switchInner + " flex-row"}>
                    <HeartBtn width = '16px' height = '100%'/>
                    구독됨
                </div>
                :
                <div className={styles.switchInner + " flex-row"}>
                    <AddBtnAlt width = '16px' height = '100%'/>
                    구독
                </div>
                }
            </label>
        </Fragment>
        :<></>
    );
}

export const MyTagPage = () =>{
    const myTags = useQuery(['Get-My-Tag-Key'],
    ()=>AxiosIns.get('/api/restrict/user/tagsFindByUserId').then((res)=>(res.data)));
    const [pageSetting,] = useAtom(pageSettingAtom);
    const [searchOn,setSearchOn] = useState(false);
    const navigate = useNavigate();
    const [page,setPage] = useState<number>(0);

    return (
        <tagContext.Provider value={{page,setPage}}>
            {
                {
                    0:<MyTagMain/>,
                    1:<TagList data={{hashtags : myTags?.data}} onClick={(e : string)=>navigate(pageSetting.tag + '?tag=' + e)}
                    className={{top : styles.rowTagContainer , inner : styles.rowTagContainer + " row-container" , tag : styles.Tags + ' flex-row'}}/>,
                    2:<div>최신별 태그 리스트</div>,
                    3:<div className={"row-container"}><MainHotTag/></div>,
                }[page]
            }
        </tagContext.Provider>
    );
}

export const MyTagMain = ()=>{
    const {setPage} = useContext(tagContext);

    return (
        <Fragment>
        <CustomBtn onClick={()=>setPage(1)} className={cx("row-container","LeftMenuTitle","flex-row")}>
            구독한 해시태그
            <RightBtn width = "16" height = "16" />
        </CustomBtn>
        <div className={cx("LeftMenuContent")}>

        </div>
        <CustomBtn onClick={()=>setPage(2)} className={cx("row-container","LeftMenuTitle","flex-row")}>
            새 해시태그
            <RightBtn width = "16" height = "16" />
        </CustomBtn>
        <div className={cx("LeftMenuContent")}>

        </div>
        <CustomBtn onClick={()=>setPage(3)} className={cx("row-container","LeftMenuTitle","flex-row")}>
            추천 해시태그
            <RightBtn width = "16" height = "16" />
        </CustomBtn>
        <div className={cx("LeftMenuContent")}>

        </div>
        </Fragment>
    );
}
//
export default TagListPage;