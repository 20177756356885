import { RightBtn } from 'icons';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Map, MapMarker } from 'react-kakao-maps-sdk';
import styles from "./map.module.css";
import {debounce, initial} from 'lodash'
import { MenuObjectAtom, MenuTargetAtom, RefreshQueryStateAtom } from 'contents/state/state';
import { useAtom, useSetAtom } from 'jotai';
import TempImg from 'icons/user/logo.png';

interface MapInterface {
    place_name : string;
    address_name : string;
    road_address_name : string;
    x : number;
    y : number;
}

interface MapSearchInterface{
    position : {
        lat : number,
        lng : number
    },
    content : string
}
export const SearchUnivForm = ({cb} : {cb : (place_name : string) => void}) =>{
    const [placeInfo,setPlaceInfo] = useState<Array<MapInterface>>([]);
    const searchRef = useRef<any>();

    const MapSearch = debounce((keyword) =>{
        if (!keyword||keyword==="") {setPlaceInfo([]); return;}
        if (window?.kakao?.maps){
        const mapAPI = window.kakao.maps;
        const ps = new mapAPI.services.Places();
        
        ps.keywordSearch(keyword,(data,status,_page)=>{
            if (status === mapAPI.services.Status.OK){
                if(data){
                    setPlaceInfo([]);
                    data.map(data=>{
                        if (data.category_name.includes('교육,학문')
                        ||data.category_name.includes("서비스,산업")){
                        setPlaceInfo((prev : any)=>[...prev,data]);
                        }
                    });
                }
            }
        })}
    },500);

    return (
        <div className = {"flex-column " + styles.searchPage}>
            <input className={styles.searchInput} type="text" onChange = {(e)=>{MapSearch(e.target.value);}} ref = {searchRef} placeholder='소속중인 대학 혹은 회사'></input>
            <div className={styles.listContainer + " flex-column scrolling"}>
                {placeInfo.map((arr : MapInterface,i)=>(
                    <MapRow arr={arr} onClick = {(_e,arr)=>{if(searchRef.current){searchRef.current.value = arr.place_name; if(cb){cb(arr.place_name);}}}}/>
                ))}
            </div>
        </div>
    );
}


export const MapRow = ({arr , onClick} : {arr : MapInterface , onClick? : (e : Event , arr : MapInterface)=>void}) =>{
    return(
        <React.Fragment>
            <div className = {styles.infoContainer + " flex-row"} onClick={(e : any)=>{if(onClick) onClick(e,arr)}}>
                <div>
                    <div className = {styles.placeName}>{arr.place_name}</div>
                    <div className = {styles.addrName}>{arr.address_name}</div>
                    <div className = {styles.addrName}>{arr.road_address_name}</div>
                </div>
                <RightBtn width = "16px" height = '16px' stroke = "inherit"/>
            </div>
            <hr/>
        </React.Fragment>
    );
}

export const MapContainer = ({draggable = true,cb,searchData = {}} : {draggable : boolean , cb : ()=>void , searchData : Object}) =>{
    const [mapPos,setMapPos] = useState({ lat: 33.450701, lng: 126.570667 });
    const isPanto = false;
    const [,QueryToRefresh] = useAtom(RefreshQueryStateAtom);

    const MapSearch = (keyword : string) =>{
        if (!keyword||keyword==="") {return;}
        if (window?.kakao?.maps){
        const ps = new window.kakao.maps.services.Places();
        ps.keywordSearch(keyword,(data,status,_page)=>{
            if (status === window.kakao.maps.services.Status.OK){
            }
        })}
    }

    useEffect(()=>{
        getCurrentPos();
        QueryToRefresh(null);
        return()=>{
            setMapPos({ lat: 33.450701, lng: 126.570667 });
        }
    },[])

    const getCurrentPos = () =>{
        navigator.geolocation.getCurrentPosition(
            (pos)=>{
                setMapPos({lat : pos.coords.latitude,lng : pos.coords.longitude});
            }
            ,(msg)=>{return false;}
            ,{
                enableHighAccuracy : true,
                maximumAge : Infinity,
                timeout : 27000
            }
        )
    }

    return (
        <Map
        center={mapPos}
        isPanto={isPanto}
        className={styles.mapContainer}
        level={3}
        draggable = {draggable}
        onClick = {cb}
        >   
        </Map>
    )
}

export const MapSelection = ({cb , initPos} : {cb : (name : string , lat : number , lng : number) => void,initPos? : {lat : number , lng : number}}) =>{
    const [markers,setMarkers] = useState<any>([]);
    const [placeInfo,setPlaceInfo] = useState<Array<MapInterface>>([]);
    const [map,setMap] = useState<any>();
    const [mapPos,setMapPos] = useState({ lat: 33.450701, lng: 126.570667 });
    const [result,setResult] = useState<MapSearchInterface>();
    const [current,setCurrent] = useState();
    const searchRef = useRef<any>();
    const isPanto = false;
    const setTarget = useSetAtom(MenuTargetAtom);
    
    const [,QueryToRefresh] = useAtom(RefreshQueryStateAtom);

    const MapSearch = debounce((keyword) =>{
        if (!keyword||keyword==="") {return;}
        if (window?.kakao?.maps){
        const mapAPI = window.kakao.maps;
        const ps = new mapAPI.services.Places();
        ps.keywordSearch(keyword,(data,status,_page)=>{
            if (status === mapAPI.services.Status.OK){
                const bounds = new mapAPI.LatLngBounds()
                if(data){
                    setPlaceInfo([]);
                    setMarkers([]);
                    data?.map((arr : any,i)=>{
                        setMarkers((p : Array<MapSearchInterface>)=>[...p,{
                            position : {
                                lat : arr.y,
                                lng : arr.x,
                            },
                            content : arr.place_name
                        }]);
                        setPlaceInfo((prev : any)=>[...prev,arr]);
                        bounds.extend(new mapAPI.LatLng(arr.y,arr.x))
                    });
                    map.setBounds(bounds);
                }
            }
        })}
    },500);

    useEffect(()=>{
        getCurrentPos();
        QueryToRefresh(null);
        return()=>{
            setMapPos({ lat: 33.450701, lng: 126.570667 });
        }
    },[])

    const getCurrentPos = () =>{
        if (initPos?.lat&&initPos?.lng)
            setMapPos({lat : initPos.lat,lng : initPos.lng})
        else
            navigator.geolocation.getCurrentPosition(
            (pos)=>{
                setMapPos({lat : pos.coords.latitude,lng : pos.coords.longitude});
            }
            ,(msg)=>{return false;}
            ,{
                enableHighAccuracy : true,
                maximumAge : Infinity,
                timeout : 27000
            }
        )
    }

    return (
        <div className={"flex-column " + styles.mapSelection}>
            <input onChange={(e)=>MapSearch(e.target?.value)} placeholder='건물명이나 주소를 입력해주세요'
            className={'input-default ' + styles.inputForm} ref={searchRef} type="text"/>
            <div className={"flex-row " + styles.mapSearchForm}>
                <Map
                center={mapPos}
                isPanto={isPanto}
                className={styles.mapContainer}
                level={3}
                onCreate={setMap}
                onDrag={()=>{
                    const latlng = map.getCenter();
                    setMapPos({lat : latlng.getLat() , lng : latlng.getLng()})
                }}
                >   
                    <MapMarker position={{lat : mapPos.lat , lng : mapPos.lng}}/>
                    {markers.map((marker : MapSearchInterface,_i: number)=>(
                        <MapMarker
                        key={`Marker-${marker.content}-${marker.position.lat}-${marker.position.lng}`}
                        position = {marker.position}
                        onClick={()=>{setResult(marker);
                        setMapPos({lat : marker.position.lat , lng : marker.position.lng});
                        map.setLevel(3);
                        if(searchRef.current) searchRef.current.value = marker.content}}
                        >
                            {result && result.content === marker.content && (
                                <div style={{color:"#000"}}>{marker.content}</div>
                            )}
                        </MapMarker>
                    ))}
                </Map>
            </div>
            <div className={styles.listContainer + " flex-column scrolling"} style={{height : '35dvh'}}>
                {placeInfo.map((arr : MapInterface,_i : Number)=>(
                    <MapRow arr={arr} onClick = {(_e,arr)=>{setMapPos({lat : arr.y,lng : arr.x}); map.setLevel(3); if(searchRef.current){searchRef.current.value = arr.place_name;}}}/>
                ))}
            </div>
            <div className={"submitBtn flex-row"} onClick={(e)=>{
                setTarget(null);
                if(cb){cb(searchRef.current.value,mapPos.lat,mapPos.lng)}
            }}>
                확인
            </div>
        </div>
    )
}