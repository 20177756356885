import { MessengerNavbar } from "contents/navbar/navbar-left";
import { ModalAtom, WindowStateAtom } from "contents/state/state";
import { LeftBtn } from "icons";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const MessageListMap = () =>{
    const windowState = useAtomValue(WindowStateAtom);
    const setNavbar = useSetAtom(ModalAtom);
    const userList = {isLoading : false , data : {pages:[{data : [
        {id : 1,target : 'TACETAHI' , recentMsg : '2018/12/12 00:00:00'},
        {id : 2,target : 'TACETAHI' , regdate : '2018/12/12 00:00:00'},
        {id : 3,target : 'TACETAHI' , regdate : '2018/12/12 00:00:00'},
        {id : 4,target : 'TACETAHI' , regdate : '2018/12/12 00:00:00'},
        {id : 5,target : 'TACETAHI' , regdate : '2018/12/12 00:00:00'},
    ]}]}}
    const [searchParam,setSearchParam] = useSearchParams();
    const user = searchParam.get('user');

    useEffect(()=>{
        if (!user) setNavbar(true);

        return ()=>{}
    },[user]);

    return (
        <div className="message-list-container">
            <div></div>
        </div>
    );
}

export default MessageListMap;