import { Fragment, ReactNode, useState } from "react";
import SettingStyle from "./setting.module.css";
import btnStyle from "contents/func/anim/css/button.module.css";
import cn from "classnames/bind";
import { CustomBtn } from "contents/func/anim/custom";
import { MenuBtn, SummarizeBtn } from "icons";
import { CollectedBooleanAtom } from "contents/state/state";
import { useAtom } from "jotai";
import { useSearchParams } from "react-router-dom";

const cx = cn.bind(SettingStyle);
const cs = cn.bind(btnStyle);

export const FeedSetting = () =>{
    const [searchParam,setSearchParam] = useSearchParams();
    const ParamHandler = (type : string,value : any) => setSearchParam((p)=>{
        if (p.get(type)){p.delete(type)}else {p.set(type,value);}
        return p;
    });
    const [collected,setCollected] = useAtom(CollectedBooleanAtom);

    return (
        <div className={cx("FeedSetting","flex-column")}>
            <SettingRow leading = {
                <p>피드 스타일</p>
            }
            actions={[
                <CustomBtn className={cs('HeaderBtn','flex-column')}
                onClick={()=>{
                    setCollected(!collected);
                    ParamHandler('collected',collected.toString());
                }}>
                    {(collected)?
                    <Fragment>
                        <MenuBtn width='18px' height='18px'/>
                        <p>피드뷰</p>
                    </Fragment>
                    :<Fragment>
                        <SummarizeBtn width='18px' height='18px'/>
                        <p>이미지뷰</p>
                    </Fragment>
                    }
                </CustomBtn>
            ]}
            />
            <SettingRow leading = {
                <p>피드 갯수</p>
            }
            actions={[
                
            ]}
            />
        </div>
    );
}

const SettingRow = ({
    leading , center , actions
}: {leading? : ReactNode , center? : ReactNode , actions? : Array<ReactNode>}) =>{
    return (
        <div className={cx("SettingRow","flex-row","row-container")}>
            <div className={cx("SettingPart")}>
                {leading}
            </div>
            <div className={cx("SettingPart")}>
                {center}
            </div>
            <div className={cx("SettingPart","flex-row")}>
                {actions?.map((arr)=>(arr))}
            </div>
        </div>
    );
}