import React,{MouseEventHandler, createContext , useEffect, useRef, useState} from 'react'
import { Link, Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SearchBtn, HomeBtn, NaviBtn, AlertBtn, MsgBtn, Logo, LeftBtn, MenuBtn, AlertBtnEmpty, UserBtn, HashTagBtn, RightBtn, AddBtnAlt, CompanyUser} from 'icons';
import { NavAlertMenu } from 'contents/interface/navbar';
import { AlertObjectAtom, AlertTargetAtom, BigMenuObjectAtom, HasAlertRefreshedAtom, MenuObjectAtom, MenuTargetAtom, ModalAtom, SearchFormOnAtom, WindowStateAtom, pageSettingAtom } from 'contents/state/state';
import NotiList from '../notification';
import { BottomCircleBtn, ScollInterFace, TopSearchForm } from 'contents/interface';
import navbarStyle from "./css/navbar.module.css";
import { CustomBtn, CustomLink } from 'contents/func/anim/custom';
import { useAtom, useSetAtom } from 'jotai';
import cn from "classnames/bind";
import { CommentWritePage } from 'contents/post/view/comments';
import { RouteNavbar } from './mobile-navbar';

const cx = cn.bind(navbarStyle);

export const navbarContext = createContext({});

const Navbar = () =>{
    const [pageSetting,] = useAtom(pageSettingAtom);
    const [windowState,] = useAtom(WindowStateAtom);
    const [HasAlertRefreshed,] = useAtom(HasAlertRefreshedAtom);
    const navigate = useNavigate();
    const Location = useLocation();
    const [menuOn,setMenuOn] = useAtom(ModalAtom);
    const [searchParam,setSearchParam] = useSearchParams();
    const category = searchParam.get('category');
    const setObject = useSetAtom(BigMenuObjectAtom);
    const setTarget = useSetAtom(MenuTargetAtom);
    const setAlertObj = useSetAtom(AlertObjectAtom);
    const setAlertTarget = useSetAtom(AlertTargetAtom);
    const page = searchParam.get('page');

    return (
        <div className = {cx("navbar-container")}>
            <RouteNavbar/>
            <div className = {cx("navbar-temp",'only-pc')} style={{top : '0'}}>
                <div className = {cx("navbar-top","flex-row")}>
                    <div className = {cx("navbar-top-left","flex-row")}>
                        <React.Fragment>
                            <CustomBtn className = {cx("navbar-left-menu-button","flex-column")} onClick={()=>setMenuOn(!menuOn)}>
                                {(menuOn)?
                                <RightBtn width='24px' height='65%' fill='inherit'/>
                                :
                                <LeftBtn width='24px' height='65%' fill='inherit'/>
                                }
                            </CustomBtn>
                            <Link to = '/' className = {cx("navbar-logo","flex-row")}><Logo width = '36' height='90%'  fill="var(--theme-white-color)" stroke="var(--theme-white-color)"></Logo></Link>
                        </React.Fragment>
                        <CustomBtn className = {cx("navbar-top-items","flex-column",)}>
                            <CompanyUser width='20px' height='18px' fill="inherit"/>
                        </CustomBtn>
                        <CustomLink className = {cx("navbar-top-items","flex-column",{linked : (Location.pathname === pageSetting.messenger)})} to={pageSetting.messenger} >
                            <MsgBtn width='20px' height='18px' fill='inherit'/>
                        </CustomLink>
                    </div>
                    <div className = {cx("navbar-top-right","flex-row",'only-pc-flex')}>
                        <CustomBtn className = {cx("navbar-top-items","flex-column",)}>
                            <SearchBtn width='18px' height='18px' fill="inherit"/>
                        </CustomBtn>
                        <CustomBtn className = {cx("navbar-top-items","flex-column")} onClick={(e)=>{setAlertObj(<NotiList/>); setAlertTarget(e);}}>
                            {HasAlertRefreshed?<AlertBtn width='20px' height='20px' fill='inherit'/>
                            :<AlertBtnEmpty width='20px' height='20px' fill='inherit' stroke='inherit'/>}
                        </CustomBtn>
                        <CustomLink className = {cx("navbar-top-items","flex-column",{linked : (Location.pathname === pageSetting.userInfo)})} to={pageSetting.userInfo}>
                            <UserBtn width='20px' height='20px' fill='inherit'/>
                        </CustomLink>
                        <NavAlertMenu/>
                    </div>
                </div>
            </div>
            <div className = {cx("navbar-temp","only-mobile")} style={{bottom : '0'}}>
                <Routes>
                    <Route path = {pageSetting.list} element={
                        (page)?
                        <BottomCircleBtn className = {cx('only-mobile-flex')} cb={(e : any)=>{setObject(<CommentWritePage/>); setTarget(e);}} object={<MsgBtn width = '22px' height = '22px' fill="white"/>}/>
                        :<BottomCircleBtn className = {cx('only-mobile-flex')} cb={()=>{navigate(pageSetting.write + '?category=' + category,{state :  {mode : 'write'}})}} object={<AddBtnAlt width = '24px' height = '24px' fill="white"/>}/>
                    }/>
                    <Route path = "*" element={<></>}/>
                </Routes>
                <div className = {cx("navbar-bottom-container","flex-row")}>
                    <CustomLink to='/' className = {cx("menu-menu-items","item1","flex-column",{linked : (Location.pathname === pageSetting.main)})}>
                        <HomeBtn width='21px' height='21px' fill="inherit" className = {cx("menu-menu-items-icon")}/>
                        <p className={cx('menu-menu-items-title')}>홈</p>
                    </CustomLink>
                    <CustomLink to = {pageSetting.messenger} className = {cx("menu-menu-items","item2","flex-column",{linked : (Location.pathname === pageSetting.messenger)})}>
                        <MsgBtn width='18px' height='18px' fill="inherit" className = {cx("menu-menu-items-icon")}/>
                        <p className={cx('menu-menu-items-title')}>메신저</p>
                    </CustomLink>
                    <CustomLink to = {pageSetting.category} className = {cx("menu-menu-items","item3","flex-column")}>
                        <MenuBtn width='21px' height='21px' fill = "inherit" className = {cx("menu-menu-items-icon")}/>
                        <p className={cx('menu-menu-items-title')}>피드</p>
                    </CustomLink>
                    <CustomLink to = {pageSetting.alert} className = {cx("menu-menu-items","item3","flex-column")}>
                        {HasAlertRefreshed?<AlertBtn width='21px' height='21px' fill='inherit'/>
                        :<AlertBtnEmpty width='21px' height='21px' fill='inherit'/>}
                        <p className={cx('menu-menu-items-title')}>알림</p>
                    </CustomLink>
                    <CustomLink to={pageSetting.userInfo} className = {cx("menu-menu-items","item4","flex-column",{linked : (Location.pathname === pageSetting.userInfo)})}>
                        <UserBtn width='21px' height='21px'
                        fill={(Location.pathname === pageSetting.userInfo)?'var(--theme-first-color-rgb)':'inherit'}
                        className = {cx("menu-menu-items-icon")}/>
                        <p className={cx('menu-menu-items-title')}>내 정보</p>
                    </CustomLink>
                </div>
            </div>
        </div>
    );
}
export default Navbar;