import { ActivityBtn, AddBtn, AddBtnAlt, BookmarkBtn, CertBtn, CompanyUser, HashTagBtn, HelpBtn, HomeBtn, LeftBtn, MenuBtn, MsgBtn, NaviBtn, RightBtn, SettingBtn, SummarizeBtn, UserBtn } from 'icons';
import { Route, Routes, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import { NavLeftMenu } from 'contents/interface/navbar';
import { CateRefAtom, CollectedBooleanAtom, NavbarLeftObjectAtom, NavbarLeftTargetAtom, TagRefAtom, UserInfoPageAtom, WindowStateAtom, pageSettingAtom } from 'contents/state/state';
import CatePage from '../post/cate/cate';
import { useQuery } from 'react-query';
import { AxiosIns } from 'contents/func/network/axios';
import { MyTagPage } from 'contents/func/tag/tagpage';
import MessStyles from 'contents/messenger/css/chat.module.css';
import { DateUnit } from 'contents/func/unit';
import navbarStyle from "./css/navbar.module.css";
import { CustomBtn, CustomLink } from 'contents/func/anim/custom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import cn from "classnames/bind";
import { UserCard } from 'contents/user/info/userInfo';
import { Fragment } from 'react/jsx-runtime';
import { ListHeader } from 'contents/post/list';

const cx = cn.bind(navbarStyle);
const cs = cn.bind(MessStyles);

export const NavbarLeft = () =>{
    const [pageSetting,] = useAtom(pageSettingAtom);
    const user = useQuery({
        queryKey : ['User-Info-Key','self'],
        queryFn : ()=>AxiosIns.get('/api/restrict/user/userInfo'),
        select : (res)=>res.data,
    });

    return (
        <nav className = {cx("navbar-left","flex-column")}>
            <NavLeftMenu/>
            <div className={cx("only-mobile-flex",'flex-column')}>
                <Routes>
                    <Route path={pageSetting.list+'/*'} element={<ListHeader/>}></Route>
                </Routes>
            </div>
            <Routes>
                <Route path = "/*" element={<CommonNavbar/>}/>
                <Route path = {pageSetting.userInfo + '/*'} element={<UserPageNavbar/>}/>
                <Route path = {pageSetting.list + '/*'} element={<ListPageNavbar/>}/>
                <Route path = {pageSetting.write} element={<ListPageNavbar/>}/>
                <Route path = {pageSetting.tag} element={<ListPageNavbar/>}/>
                <Route path = {pageSetting.messenger} element={<MessengerNavbar/>}/>
                <Route path = {pageSetting.setting} element={<SettingNavbar/>}/>
            </Routes>
            <div className = {cx("navbar-menu","menu-bottom","flex-row","only-pc-flex")}>
                <CustomLink to = {pageSetting.setting} className = {cx("menu-setting-items","item1","btn-style-grey-back")}>
                    <SettingBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon")}/>
                    <p className = {cx("menu-menu-items-text")}>설정</p>
                </CustomLink>
                <CustomLink to = {pageSetting.help} className = {cx("menu-setting-items","item2","btn-style-grey-back")}>
                    <HelpBtn width='20px' height='20px' fill='inherit' stroke='inherit' className = {cx("menu-menu-items-icon")}/>
                    <p className = {cx("menu-menu-items-text")}>도움말</p>
                </CustomLink>
            </div>
        </nav>
    );
}

const CommonNavbar = () => {
    const setObject = useSetAtom(NavbarLeftObjectAtom);
    const setTarget = useSetAtom(NavbarLeftTargetAtom);
    const Location = useLocation();
    const [pageSetting,] = useAtom(pageSettingAtom);

    return(
    <div className = {cx("navbar-menu","menu-home","flex-column","only-pc-flex","scrolling")}>
        <CustomLink to = {pageSetting.main}
        className = {cx("menu-menu-items","item1","flex-row",{linked : (Location.pathname===pageSetting.main)})} >
            <HomeBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>홈</p>
        </CustomLink>
        <div className = {cx("menu-menu-title","item1","flex-column")}>
            <hr/>
            <p>피드의</p>
        </div>
        <CustomBtn className = {cx("menu-menu-items","item2","flex-row",{linked : (Location.pathname===pageSetting.category)})}
        onClick={(e)=>{setTarget(e); setObject(<CatePage/>);}}>
            <MenuBtn width='16px' height='16px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>카테고리</p>
        </CustomBtn>
        <CustomBtn className = {cx("menu-menu-items","item3","flex-row",{linked : (Location.pathname===pageSetting.myTag)})}
        onClick={(e)=>{setTarget(e); setObject(<MyTagPage/>);}}>
            <HashTagBtn width='16px' height='16px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>해시태그</p>
        </CustomBtn>
        <div className = {cx("menu-menu-title","item1","flex-column")}>
            <hr/>
            <p>나의</p>
        </div>
        <CustomLink to = {pageSetting.bookmark}  state = {{title : '북마크' , description : '북마크로 등록 해놓으신 게시물입니다'}}
        className = {cx("menu-menu-items","item4","flex-row",{linked : (Location.pathname===pageSetting.bookmark)})}>
            <BookmarkBtn width='20px' height='16px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>북마크</p>
        </CustomLink>
        <CustomLink to = {pageSetting.map}
        className = {cx("menu-menu-items","item5","flex-row",{linked : (Location.pathname===pageSetting.map)})}>
            <NaviBtn width='20px' height='16px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>주변</p>
        </CustomLink>
    </div>
    );
}

const UserPageNavbar = () => {
    const [page,setPage] = useAtom(UserInfoPageAtom);
    const navigate = useNavigate();
    return(
    <div className = {cx("navbar-menu","menu-home","flex-column","only-pc-flex","scrolling")}>
        <CustomBtn onClick={()=>setPage(0)} className = {cx("menu-menu-items","item1","flex-row",{linked : (page == 0)})}>
            <UserBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>유저 홈</p>
        </CustomBtn>
        <CustomBtn onClick={()=>setPage(1)} className = {cx("menu-menu-items","item1","flex-row",{linked : (page == 1)})}>
            <MenuBtn width='20px' height='20px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>피드</p>
        </CustomBtn>
        <CustomBtn onClick={()=>setPage(2)} className = {cx("menu-menu-items","item1","flex-row",{linked : (page == 2)})}>
            <AddBtnAlt width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>내 구독</p>
        </CustomBtn>
        <CustomBtn onClick={()=>setPage(3)} className = {cx("menu-menu-items","item1","flex-row",{linked : (page == 3)})}>
            <ActivityBtn width='20px' height='20px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>활동</p>
        </CustomBtn>
        <hr/>
        <CustomBtn onClick={()=>navigate(-1)} className = {cx("menu-menu-items","item5","flex-row","btn-style-grey-back")} >
            <LeftBtn width='20px' height='16px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>뒤로가기</p>
        </CustomBtn>
    </div>
    );
}

const ListPageNavbar = () => {
    const setObject = useSetAtom(NavbarLeftObjectAtom);
    const setTarget = useSetAtom(NavbarLeftTargetAtom);
    const setCateRef = useSetAtom(CateRefAtom);
    const setTagRef = useSetAtom(TagRefAtom);
    const navigate = useNavigate();
    const [pageSetting,] = useAtom(pageSettingAtom);
    const [searchParam,setSearchParam] = useSearchParams();
    const category = searchParam.get('category');

    return(
        <Fragment>
            <MobileListNavbar/>
            <div className = {cx("navbar-menu","menu-home","flex-column","only-pc-flex","scrolling")}>
                <CustomLink to = {pageSetting.main} className = {cx("menu-menu-items","item1","flex-row","btn-style-grey-back")}>
                    <HomeBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>홈</p>
                </CustomLink>
                <div className = {cx("menu-menu-title","item1","flex-column")}>
                    <hr/>
                    <p>피드의</p>
                </div>
                <CustomBtn className = {cx("menu-menu-items","item2","flex-row","btn-style-grey-back")} 
                ref={(el)=>setCateRef(el)}
                onClick={(e)=>{
                    setTarget(e); setObject(<CatePage/>);}}>
                    <MenuBtn width='20px' height='20px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>카테고리</p>
                </CustomBtn>
                <CustomBtn className = {cx("menu-menu-items","item3","flex-row","btn-style-grey-back")}
                onClick={(e)=>{setTarget(e); setObject(<MyTagPage/>);}}>
                    <HashTagBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>해시태그</p>
                </CustomBtn>
                <CustomLink to = {pageSetting.write + '?category=' + category} state={{mode : 'write'}} className = {cx("menu-menu-items","item5","flex-row","btn-style-grey-back")} >
                    <AddBtnAlt width='20px' height='20px' fill='inherit' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>새로운 작성</p>
                </CustomLink>
                <div className = {cx("menu-menu-title","item1","flex-column")}>
                    <hr/>
                </div>
                <CustomBtn onClick={()=>navigate(-1)} className = {cx("menu-menu-items","item5","flex-row","btn-style-grey-back")} >
                    <LeftBtn width='20px' height='16px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>뒤로가기</p>
                </CustomBtn>
            </div>
        </Fragment>
    );
}
interface usertmp {
    isLoading : boolean,
    isSuccess : boolean,
    data : {
        pages : [
            {data : usertemp[]}
        ]
    }
}
interface usertemp{
    id : string,
    room : string,
    profile : string,
    recentMsg : string,
    recentMsgDate : string
}
export const MessengerNavbar = () => {
    const navigate = useNavigate();
    const userTemp = {isLoading : false , isSuccess : true , data : {pages:[{data : [
            {id : 1 , room : '리누스 토르발즈' , profile : "https://pds.joongang.co.kr/news/component/htmlphoto_mmdata/201406/01/htm_20140601042323442.jpg" , recentMsg : 'Nvidia,Fxxk you!' , recentMsgDate : '2024-05-24 03:18:00'},
            {id : 2 , room : '자바스크립트' , profile : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZeab83jLxJNlqggbc0T40gUZ9D2VGLUg6cI2BNJ6Fwg&s" , recentMsg : '잡아' , recentMsgDate : '2024-05-24 03:18:00'},
            {id : 3 , room : '비둘기' , profile : "https://images.uncyc.org/kr/c/c5/%EB%B9%84%EB%91%98%EA%B8%B0%EC%95%BC_%EB%A8%B9%EC%9E%90_%EA%B5%AC%EA%B5%AC%EA%B5%AC%EA%B5%AC.PNG" , recentMsg : '비둘기야 먹자' , recentMsgDate : '2024-05-24 03:18:00'},
            {id : 4 , room : '일론 머스크' , profile : "https://media.tenor.com/J5qyn3N96j0AAAAM/%EC%9D%BC%EB%A1%A0%EB%A8%B8%EC%8A%A4%ED%81%AC-%EB%94%A5%ED%8E%98%EC%9D%B4%ED%81%AC.gif" , recentMsg : '화성 갈끄니까~' , recentMsgDate : '2024-05-24 03:18:00'},
            {id : 5 , room : '자바' , profile : "https://velog.velcdn.com/images/mainfn/post/b02ee454-620a-4b2a-80ce-48ab22f9e5b4/image.png" , recentMsg : '잡으라고' , recentMsgDate : '2024-05-24 03:18:00'},
            {id : 6 , room : 'Thruston Waffles' , profile : "https://static.wikia.nocookie.net/7da7a2c2-962b-4c22-b609-f8a56333fde0" , recentMsg : 'MEOW' , recentMsgDate : '2024-05-24 03:18:00'},
            {id : 7 , room : '일이삼사오륙칠팔구십일이삼사오륙칠팔구십' , profile : "" , recentMsg : '님 신고' , recentMsgDate : '2024-05-24 03:18:00'},
    ]}]}};

    return(
    <div className = {cx("navbar-menu","menu-home","flex-column","scrolling")}>
        <div className = {cs('Menu',"flex-row","btn-style-grey-back",'only-pc-flex')}>
            <CustomBtn className = {cs('userInfo',"flex-row")} onClick={()=>navigate('..')}>
                <LeftBtn width='20px' height='18px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                <p className = {cs('MenuTitle')}>뒤로가기</p>
            </CustomBtn>
        </div>
        {userTemp.data?.pages?.map((p : any)=>
            (p?.data?.length > 0)&&p?.data?.map((arr : usertemp,i : Number)=>(
                <CustomBtn className = {cs("Menu","flex-row","btn-style-grey-back")}>
                    <div className = {cs('profileContainer')}>
                        <img width='100%' height='100%' src={arr.profile} className = {cs('profile',"flex-column")}/>
                    </div>
                    <div className = {cs('userInfo',"flex-column")}>
                        <p className = {cs('MenuTitle')}>{arr.room}</p>
                        <p className = {cs('MenuRecent')}>{arr.recentMsg}</p>
                        <p className = {cs('MenuRecentDate')}>{DateUnit(arr.recentMsgDate)}</p>
                    </div>
                </CustomBtn>
            )))
        }
    </div>
    );
}

const SettingNavbar = () => {
    return(
    <div className = {cx("navbar-menu","menu-home","flex-column","only-pc-flex","scrolling")}>
        <CustomLink to = {''} className = {cx("menu-menu-items","item1","flex-row","btn-style-grey-back")}>
            <p className = {cx("menu-menu-items-text")}>내 계정</p>
        </CustomLink>
        <CustomLink to = {''} className = {cx("menu-menu-items","item1","flex-row","btn-style-grey-back")}>
            <p className = {cx("menu-menu-items-text")}>테마설정</p>
        </CustomLink>
        <CustomLink to = {''} className = {cx("menu-menu-items","item1","flex-row","btn-style-grey-back")}>
            <p className = {cx("menu-menu-items-text")}>위치설정</p>
        </CustomLink>
        <CustomLink to = {''} className = {cx("menu-menu-items","item1","flex-row","btn-style-grey-back")}>
            <p className = {cx("menu-menu-items-text")}>고급설정</p>
        </CustomLink>
        <hr/>
        <CustomLink to = '..' className = {cx("menu-menu-items","item5","flex-row","btn-style-grey-back")}>
            <LeftBtn width='20px' height='18px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>뒤로가기</p>
        </CustomLink>
    </div>
    );
}

const MobileListNavbar = () => {
    const [pageSetting,] = useAtom(pageSettingAtom);
    const Location = useLocation();
    const [collected,setCollected] = useAtom(CollectedBooleanAtom);

    return(
    <div className = {cx("navbar-menu","only-mobile-flex","menu-home","flex-column","scrolling")}>
        <div className = {cx("menu-menu-title","item1","flex-column")}>
            <hr/>
            <p>설정</p>
        </div>
        <CustomBtn className = {cx("menu-menu-items","item3","flex-row","btn-style-grey-back")}
        onClick={()=>setCollected(!collected)}>
            {(collected)?
                <Fragment>
                    <MenuBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>피드 뷰</p>
                </Fragment>
            : 
                <Fragment>
                    <SummarizeBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>이미지 뷰</p>
                </Fragment>  
            }
        </CustomBtn>
        <div className = {cx("menu-menu-title","item1","flex-column")}>
            <hr/>
            <p>이동</p>
        </div>
        <CustomLink className = {cx("menu-menu-items","item3","flex-row","btn-style-grey-back")}
        to={pageSetting.myTag}>
            <HashTagBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>태그</p>
        </CustomLink>
        <CustomLink to = {pageSetting.map}
        className = {cx("menu-menu-items","item5","flex-row",{linked : (Location.pathname===pageSetting.map)})}>
            <NaviBtn width='20px' height='16px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>주변</p>
        </CustomLink>
    </div>
    );
}