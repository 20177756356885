import { LoadingSpiner } from "icons";
import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import style from "./css/motion.module.css";
import cn from "classnames/bind";

const cx = cn.bind(style);
const AnimJson = require("icons/common/loading.json");

export const LoadingBar = (
    {style , autoPlay = true , className}
    :{style? : object , autoPlay? : boolean , className? : string}) => {
    const loadingRef = useRef<any>();
    const anim = {
        loop : true,
        autoplay : true,
        animationData : AnimJson,
    };

    return (
        <div className={cx("loading-bar","flex-row") + " " + className}
        style={{...style}} ref={loadingRef}>
            <Lottie options={anim} width = "75%" height = "75%"/>
        </div>
    );
}
//<LoadingSpiner className="loading-spiner" width="75%" height='75%' fill={spinerColor}/>