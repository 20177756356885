import { dayMax } from ".";
import { useCallback, useEffect, useRef, useState } from "react";
import { div } from "../anim/motions";
import { throttle } from "lodash";
import styles from "./datePicker.module.css";

const DatePicker = ({defaultDate = new Date(),size,format,cb}) => {
    const startYear = 1970;
    const endYear = new Date().getFullYear();
    const [year,setYear] = useState([]);
    const [Month,setMonth] = useState([]);
    const [date,setDate] = useState([]);
    const [selected,setSelected] = useState({
        year : defaultDate.getFullYear(),
        month : defaultDate.getMonth() + 1,
        date : defaultDate.getDate()
    });
    const onIntersect = (type,value)=>{
        setSelected((prev=>{return {...prev , [type] : value}}));
    }
    const onScrollSet = throttle((type,e)=>{
        const index = Math.round(e.target?.scrollTop / (e.target?.children[0]?.clientHeight));
        onIntersect(type,e.target?.children[index]?.outerText);
    },500)
    const getDate = useCallback(()=>{
        setYear([]);
        setMonth([]);
        setDate([]);
        const daymax = dayMax(selected.year,selected.month);
        for(let i=endYear;i>=startYear;i--){
            setYear((prev)=>[...prev,i]);
        }
        const currentMonthMax = (selected.year == new Date().getFullYear())?new Date().getMonth()+1 : 12;
        const currentDayMax = 
        (selected.year == new Date().getFullYear() && selected.month == new Date().getMonth() + 1)?new Date().getDate() : daymax;
        for(let i=1;i<=currentMonthMax;i++){
            setMonth((prev)=>[...prev,i]);
        }
        for(let i=1;i<=currentDayMax;i++){
            setDate((prev)=>[...prev,i]);
        }
    },[selected]);
    useEffect(()=>{
        getDate();
        return ()=>{
            setDate([]);
            setMonth([]);
            setYear([]);
        };
    },[selected]);

    return (
        <div className={styles.pickerPage} style={{height : size?.height , width : size?.width}}>
            <h3 className={styles.pickerHeader}>날짜 선택</h3>
            <h5>드래그해서 날짜를 선택하고 확인을 눌러주세요</h5>
            <div className={styles.pickerCurrentDate}>
                <div>
                    <div className={styles.pickerYearExample}>{(selected?.year||new Date().getFullYear())}</div>
                </div>
                <label>{format?.year}</label>
                <div>
                    <div className={styles.pickerMonthExample} PageKey = {selected?.month}>{(selected?.month||new Date().getMonth() + 1)}</div>
                </div>
                <label>{format?.month}</label>
                <div>
                    <div className={styles.pickerDateExample} PageKey = {selected?.date}>{(selected?.date||new Date().getDate())}</div>
                </div>
                <label>{format?.date}</label>
            </div>
            <div className={styles.pickerContainer}>
                <div className={styles.datePicker + ' picker-year'} onScroll={(e)=>onScrollSet('year',e)}>
                    {year.map((y,_)=><div className={styles.pickerList + ` flex-row year-${y}`} key={y}>{y}</div>)}
                </div>
                <label className="date-format format-year">{format?.year}</label>
                <div className={styles.datePicker + ' picker-month'} onScroll={(e)=>onScrollSet('month',e)}>
                    {Month.map((m,_)=><div className={styles.pickerList + ` flex-row month-${m}`} key={m}>{m}</div>)}
                </div>
                <label className="date-format format-month">{format?.month}</label>
                <div className={styles.datePicker + ' picker-date'} onScroll={(e)=>onScrollSet('date',e)}>
                    {date.map((d,_)=><div className={styles.pickerList + ` flex-row date-${d}`} key={d}>{d}</div>)}
                </div>
                <label className="date-format format-date">{format?.date}</label>
            </div>
            <button className={"submitBtn"} onClick={()=>{if(cb) cb(new Date(selected.year,selected.month-1,selected.date,0,0,0))}}>확인</button>
        </div>
    );
}
export default DatePicker;